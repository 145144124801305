import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { useCredentialsStore } from '@/stores/credentials'

import { getRefreshToken, type refreshTokenResponse } from './oauth'

export const client: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SEND_CORE_API_HOST,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 35000, // shipcloud API timeout is 30s
  responseType: 'json'
})

client.interceptors.request.use(
  (config) => {
    const credentials = useCredentialsStore()
    const accessToken = credentials.accessToken
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

let isRefreshing = false

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config
    const credentials = useCredentialsStore()
    const refreshToken = credentials.refreshToken
    if (error.response) {
      if (error.response.status === 401 && refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const params = new URLSearchParams({
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              client_id: import.meta.env.VITE_OAUTH_CLIENT_ID
            })

            const { data }: { data: refreshTokenResponse } = await client.post(
              `${import.meta.env.VITE_SEND_CORE_APP_HOST}/${getRefreshToken.path}`,
              params.toString(),
              {
                method: getRefreshToken.method,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                },
                responseType: 'json'
              }
            )

            credentials.$patch((state) => {
              state.accessToken = data.access_token
              state.refreshToken = data.refresh_token
            })

            error.config.headers.Authorization = `Bearer ${data.access_token}`

            return client(originalRequest)
          } finally {
            isRefreshing = false
          }
        }
      }
    }

    return Promise.reject(error)
  }
)

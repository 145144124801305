import { defineStore } from 'pinia'
import { ref } from 'vue'

const DEFAULT_MODE = 'v1'
const RECHECK_MINUTES = 5

export const useAuthenticationStore = defineStore(
  'authentication',
  () => {
    type AuthenticationMode = 'v1' | 'v2'

    const authenticationMode = ref<AuthenticationMode>(DEFAULT_MODE)
    const lastChecked = ref<string>()

    const recheck = (): boolean => {
      if (lastChecked.value) {
        const now = new Date()
        const last = new Date(lastChecked.value)
        const elapsed = now.getTime() - last.getTime()
        return elapsed > 60_000 * RECHECK_MINUTES
      } else return true
    }

    const checked = () => {
      lastChecked.value = new Date().toISOString()
    }

    const clear = () => {
      authenticationMode.value = DEFAULT_MODE
      lastChecked.value = undefined
    }

    return { authenticationMode, lastChecked, checked, recheck, clear }
  },
  { persist: true }
)

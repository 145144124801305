import { z } from 'zod'

export const Address = z
  .object({
    id: z.string().nullable(),
    company: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    care_of: z.string().nullable(),
    street: z.string().nullable(),
    street_no: z.string().nullable(),
    zip_code: z.string().nullable(),
    city: z.string().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable()
  })
  .partial()
export type Address = z.infer<typeof Address>

export const GetDefaultShippingAddress = {
  method: 'GET',
  path: `v1/default_shipping_address`
}

export const GetDefaultReturnsAddress = {
  method: 'GET',
  path: `v1/default_returns_address`
}

export const editableAddressProperties = [
  'company',
  'first_name',
  'last_name',
  'care_of',
  'street',
  'street_no',
  'zip_code',
  'city',
  'country',
  'phone'
] as const

export const defaultAddressObject = {
  id: null,
  company: null,
  first_name: null,
  last_name: null,
  care_of: null,
  street: '',
  street_no: null,
  zip_code: null,
  city: '',
  country: '',
  email: null,
  phone: ''
}

export const getDefaultAddressObject = (): Address => Object.assign({}, defaultAddressObject)

import type { PaginationOptions } from '@/api/shipcloud'
import { Address, getDefaultAddressObject } from '@/api/shipcloud/address'
import { toQueryParams } from '@/helpers'
import { z } from 'zod'
import { AdditionalService } from './additional_service'
import { CustomsDeclaration, NewCustomsDeclaration } from './customs_declaration'
import { NewPackage, Package } from './package'
import type { ShipmentFilter } from './shipment_filter'
import { Surcharges } from './surcharge'

export const Shipment = z.object({
  id: z.string(),
  carrier: z.string(),
  carrier_tracking_no: z.string(),
  created_at: z.string(),
  service: z.string(),
  reference_number: z.string().nullable(),
  price: z.string(),
  label_url: z.string().nullable(),
  label_voucher_url: z.string().optional(),
  notification_email: z.string().optional(),
  to: z.optional(Address),
  from: Address,
  packages: z.array(Package),
  customs_declaration: z.optional(CustomsDeclaration),
  additional_services: z.array(AdditionalService).optional(),
  surcharges: z.optional(Surcharges)
})
export type Shipment = z.infer<typeof Shipment>
export const NewShipment = Shipment.omit({
  id: true,
  carrier_tracking_no: true,
  created_at: true,
  label_url: true,
  label_voucher_url: true,
  packages: true,
  price: true
}).extend({
  create_shipping_label: z.boolean(),
  customs_declaration: z.optional(NewCustomsDeclaration),
  from: Address,
  package: NewPackage,
  to: z.optional(Address),
  description: z.string().optional()
})
export type NewShipment = z.infer<typeof NewShipment>
export const defaultNewShipment: Partial<NewShipment> = {
  carrier: '',
  service: '',
  reference_number: '',
  notification_email: '',
  create_shipping_label: true,
  package: { type: 'parcel' } as NewPackage,
  additional_services: []
}
export const getDefaultNewShipment = () =>
  Object.assign(
    {},
    {
      ...defaultNewShipment,
      from: getDefaultAddressObject(),
      to: getDefaultAddressObject()
    }
  ) as NewShipment
export const InlineReturn = z.object({
  create: z.boolean(),
  reference_number: z.string(),
  defaultReturnsAddressPresent: z.boolean()
})
export type InlineReturn = z.infer<typeof InlineReturn>

export type GetShipmentsResponse = {
  total: number
  shipments: Shipment[]
}

export const GetShipments = (filterOptions: ShipmentFilter, pagination: PaginationOptions) => ({
  path: `v1/shipments?${toQueryParams(filterOptions, pagination).toString()}`,
  method: 'GET'
})

export const GetShipment = (id: string) => ({
  path: `v1/shipments/${id}`,
  method: 'GET'
})

export const CreateShipment = (shipment: NewShipment) => ({
  path: `v1/shipments`,
  method: 'POST',
  data: editableShipmentToCreationPayload(shipment)
})

export const DeleteShipment = (id: string) => ({
  path: GetShipment(id).path,
  method: 'DELETE'
})

const editableShipmentToCreationPayload = (shipment: NewShipment) => {
  shipment.customs_declaration?.items.forEach((item) => {
    delete item['formId']
  })
  return shipment
}

<script setup lang="ts">
import { DialogBox } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

import type { ShippingRule } from '@/api/shipcloud/shipping_rules'
import { ShippingRuleEditor } from '../ShippingRuleEditor'

const { t } = useI18n()

defineProps<{
  shippingRule: ShippingRule
}>()

defineEmits<{
  update: [ShippingRule]
}>()

const isOpen = defineModel<boolean>({ default: false })
</script>

<template>
  <DialogBox
    :dialog-title="t('Office.ShippingRules.EditDialog.title')"
    :tooltip="t('Office.ShippingRules.EditDialog.headerTooltip')"
    dialog-size="large"
    v-model="isOpen"
  >
    <ShippingRuleEditor
      v-if="isOpen"
      class="p-5"
      :shipping-rule="shippingRule"
      :str-button-cancel="t('Office.ShippingRules.EditDialog.discard')"
      :str-button-submit="t('Office.ShippingRules.EditDialog.apply')"
      :str-outputs-add-row="t('Office.ShippingRules.EditDialog.add_output')"
      :str-outputs-heading="t('Office.ShippingRules.EditDialog.outputs')"
      :str-rule-selector-placeholder="t('Office.ShippingRules.EditDialog.field_to_match')"
      :str-shipping-rule-schema-from-city="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaFromCity')
      "
      :str-shipping-rule-schema-from-company="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaFromCompany')
      "
      :str-shipping-rule-schema-from-country="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaFromCountry')
      "
      :str-shipping-rule-schema-from-zip-code="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaFromZipCode')
      "
      :str-shipping-rule-schema-to-city="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaToCity')
      "
      :str-shipping-rule-schema-to-company="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaToCompany')
      "
      :str-shipping-rule-schema-to-country="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaToCountry')
      "
      :str-shipping-rule-schema-to-zip-code="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaToZipCode')
      "
      :str-shipping-rule-schema-package-weight="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaPackageWeight')
      "
      :str-shipping-rule-schema-package-longest-side="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaPackageLongestSide')
      "
      :str-shipping-rule-schema-receiver-country-in-eea="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaReceiverCountryInEea')
      "
      :str-shipping-rule-schema-receiver-company-not-empty="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaReceiverCompanyNotEmpty')
      "
      :str-shipping-rule-schema-return-shipment="
        t('Office.ShippingRules.EditDialog.shippingRuleSchemaReturnShipment')
      "
      @cancel="isOpen = false"
      @update="$emit('update', $event)"
    />
  </DialogBox>
</template>

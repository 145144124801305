<script setup lang="ts">
import type { CarrierServiceCombination } from '@/api/shipcloud/shipping_rules'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps<{
  resolutionResult: CarrierServiceCombination[]
}>()
</script>

<template>
  <div class="overflow-hidden rounded-lg bg-white p-5 text-sm shadow-lg ring-1">
    <p class="font-medium">
      {{ t('Office.ShippingRules.expectedOutput') }}
    </p>
    <ul v-if="resolutionResult.length" class="text-gray-500">
      <template v-for="tuple in resolutionResult" :key="tuple.carrier">
        <li class="mt-3">
          {{ t('Shipment.carrier') }}: {{ t(`Shipment.carriers.${tuple.carrier}`) }}
        </li>
        <li>{{ t('Shipment.service') }}: {{ t(`Shipment.services.${tuple.service}`) }}</li>
      </template>
    </ul>
    <p v-else class="text-gray-500">{{ t('Office.ShippingRules.emptyOutput') }}</p>
  </div>
</template>

<script setup lang="ts">
import type { ShippingRule, ShippingRuleTree } from '@/api/shipcloud/shipping_rules'
import ShippingRuleNode, { type NodeEvent } from '@/components/ShippingRuleNode.vue'
import ShippingRuleRoot from '@/components/ShippingRuleRoot.vue'
import ShippingRuleEditDialog from '@/components/ShippingRules/EditDialog.vue'
import { useShippingRulesGraph } from '@/composables/useShippingRulesGraph'
import { SuiteButton, ThemeEnum } from '@shipcloud/suite-components'
import {
  VueFlow,
  useVueFlow,
  type ElementData,
  type GraphNode,
  type NodeChange,
  type NodeDragEvent,
  type NodeMouseEvent,
  type NodeSelectionChange
} from '@vue-flow/core'
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { onNodeDragStart, onNodeDragStop, onNodesChange, onNodeClick, fitView } = useVueFlow()
const { t } = useI18n()
export type ShippingRuleTreeSaveEvent = {
  shippingRuleTree: ShippingRuleTree
}
const props = defineProps<{
  initialShippingRuleTree: ShippingRuleTree
}>()
const emit = defineEmits<{
  (e: 'save', event: ShippingRuleTreeSaveEvent): void
}>()
const shippingRuleTree = ref(props.initialShippingRuleTree)
const {
  nodes,
  edges,
  addNodeBelow,
  addNodeRight,
  updateNodeOrder,
  removeNode,
  selectNode,
  findNode,
  editNode,
  buildTree
} = useShippingRulesGraph(shippingRuleTree.value)

const setUnpersisted = () => (shippingRuleTree.value.persisted = false)
const openEditDialog = (nodeId: string) => {
  editNodeData.value = findNode(nodeId).data.shippingRule
  editNodeId.value = nodeId
  isEditing.value = true
}
const addBelow = (e: NodeEvent) => {
  const newNodeId = addNodeBelow(e.nodeId)
  openEditDialog(newNodeId)
  setUnpersisted()
}
const addRight = (e: NodeEvent) => {
  const newNodeId = addNodeRight(e.nodeId)
  openEditDialog(newNodeId)
  setUnpersisted()
}
const remove = (e: NodeEvent) => {
  removeNode(e.nodeId)
  setUnpersisted()
}
const isEditing = ref(false)
const editNodeData = ref<ShippingRule | undefined>()
const editNodeId = ref<string | undefined>()
const edit = (e: NodeEvent) => openEditDialog(e.nodeId)

const update = (e: ElementData) => {
  if (!editNodeId.value) return
  editNode(editNodeId.value, e)
  editNodeData.value = undefined
  editNodeId.value = undefined
  isEditing.value = false
  setUnpersisted()
}
onNodeDragStart((e: NodeDragEvent) => handleNodeDragStart(e.node))
onNodeDragStop((e: NodeDragEvent) => {
  handleNodeDragStop(e.node)
  setUnpersisted()
})

const rootDialogOpen = ref(false)

onNodeClick((e: NodeMouseEvent) => {
  if (e.node.id === 'root') {
    rootDialogOpen.value = true
  }
})

const select = (changes: NodeChange[]) => {
  const selectedNodeChanges = changes.filter((change) => change.type == 'select')
  selectedNodeChanges.forEach((change) => {
    selectNode((change as NodeSelectionChange).id)
  })
}
onNodesChange((changes: NodeChange[]) => select(changes))
const save = () => {
  const shippingRuleTree = buildTree()
  emit('save', { shippingRuleTree })
}
const getNodeParentElement = (node: GraphNode): HTMLElement | null => {
  return document.querySelector(`[data-id=${node?.parentNode}]`)
}
const handleNodeDragStart = (node: GraphNode): HTMLElement | undefined => {
  return setParentNodeElementVisibility(node, true)
}
const handleNodeDragStop = (node: GraphNode): HTMLElement | undefined => {
  updateNodeOrder(node.id, node.position.y)
  return setParentNodeElementVisibility(node, false)
}
const setParentNodeElementVisibility = (
  node: GraphNode,
  visible: boolean
): HTMLElement | undefined => {
  const parentNodeElement = getNodeParentElement(node)
  if (parentNodeElement) {
    parentNodeElement.style.visibility = visible ? 'visible' : 'hidden'
    return parentNodeElement
  }
}
onMounted(() => {
  window.addEventListener('resize', handleWindowResize)
})
onUnmounted(() => {
  window.removeEventListener('resize', handleWindowResize)
})
/* c8 ignore start */
const handleWindowResize = () => {
  fitView()
}
/* c8 ignore end */

defineExpose({
  addRight,
  addBelow,
  edit,
  editNodeData,
  remove,
  select,
  nodes,
  edges,
  getNodeParentElement,
  handleNodeDragStart,
  handleNodeDragStop,
  save,
  shippingRuleTree,
  update
})
</script>

<template>
  <div class="h-[6rem] border-b-2 border-gray-200 bg-white py-2">
    <div
      class="mx-auto flex h-full max-w-screen-2xl flex-row items-center justify-between gap-3 px-4 lg:px-10"
    >
      <div>
        <div class="text-xl font-bold">{{ t('Office.ShippingRules.header') }}</div>
        <span class="suite-text-gray-600 text-sm leading-relaxed">
          {{ t('Office.ShippingRules.subheader') }}
        </span>
      </div>
      <div class="flex gap-2 justify-self-end text-nowrap">
        <SuiteButton
          :theme="ThemeEnum.BLUE_BORDERLESS"
          icon="Maximize2"
          class="h-12 w-12 px-3"
          @click="fitView"
        />
        <SuiteButton
          v-if="shippingRuleTree.persisted"
          data-testid="inactive-save-shipping-rule-tree"
          :theme="ThemeEnum.BLUE_OUTLINE"
          class="group cursor-default"
        >
          <span class="hidden group-hover:block">{{ t('Office.ShippingRules.unchanged') }}</span>
          <span class="block group-hover:hidden">{{ t('Office.ShippingRules.submit') }}</span>
        </SuiteButton>
        <SuiteButton v-else data-testid="save-shipping-rule-tree" @on-click="save">
          {{ t('Office.ShippingRules.submit') }}
        </SuiteButton>
      </div>
    </div>
  </div>

  <ShippingRuleEditDialog
    v-if="editNodeId && editNodeData"
    v-model="isEditing"
    :shipping-rule="editNodeData"
    @update="update"
  />
  <div class="h-[calc(100%-6rem)] w-full">
    <VueFlow :nodes="nodes" :edges="edges" :fit-view-on-init="true">
      <template #node-shipping-rule="nodeProps">
        <ShippingRuleNode
          :node-props="nodeProps"
          @add-below="addBelow"
          @add-right="addRight"
          @remove="remove"
          @edit="edit"
        />
      </template>
      <template #node-input="nodeProps">
        <ShippingRuleRoot v-model="rootDialogOpen" :node-props="nodeProps" @save="save" />
      </template>
      <template #node-shipping-rule-parent></template>
    </VueFlow>
  </div>
</template>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

html,
body,
#app {
  margin: 0;
  height: 100%;
}

:root,
.vue-flow__node-input {
  --vf-handle: #66d3ee;
  --vf-connection-path: #66d3ee;
  --vf-node-color: #66d3ee;
  --vf-node-text: #7e828d;
  padding: 0px;
}
</style>

import { z } from 'zod'

export const TokenPayload = z
  .object({
    exp: z.number(),
    sub: z.string()
  })
  .passthrough()
export type TokenPayload = z.infer<typeof TokenPayload>

export const parseTokenPayload = (payload: unknown): TokenPayload | undefined => {
  const parsed = TokenPayload.safeParse(payload)
  if (parsed.success) return parsed.data
}

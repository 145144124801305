<script setup lang="ts">
import { useShippingRuleTreesStore } from '@/stores/shippingRuleTrees'
import { SuiteButton, SuiteIcon, ThemeEnum, type SuiteIconName } from '@shipcloud/suite-components'
import { Handle, Position, type NodeProps } from '@vue-flow/core'
import { NodeToolbar } from '@vue-flow/node-toolbar'
import { computed } from 'vue'
import {
  findOperatorForRule,
  findSchemaIndexForRule,
  shippingRuleSchemas
} from './ShippingRuleEditor'
import ResolutionOutput from './ShippingRules/ResolutionOutput.vue'

const RESOLUTION_STATE_COLORS = {
  resolved_no_match: 'bg-red-300',
  resolved_final_match: 'bg-green-300',
  unresolved: 'bg-gray-300'
}
export type NodeEvent = {
  nodeId: string
}
const props = defineProps<{
  nodeProps: NodeProps
}>()
defineEmits<{
  (e: 'add-below', event: NodeEvent): void
  (e: 'add-right', event: NodeEvent): void
  (e: 'remove', event: NodeEvent): void
  (e: 'edit', event: NodeEvent): void
}>()
const store = useShippingRuleTreesStore()
const resolutionState = props.nodeProps.data.shippingRule?.resolution_state
const resolutionStateColor =
  RESOLUTION_STATE_COLORS[resolutionState as keyof typeof RESOLUTION_STATE_COLORS]

const schemaIndex = computed(() =>
  findSchemaIndexForRule(props.nodeProps.data.shippingRule, shippingRuleSchemas)
)
const schema = computed(() => shippingRuleSchemas?.[schemaIndex.value])
const operator = computed(() =>
  findOperatorForRule(props.nodeProps.data.shippingRule, schema.value)
)

const node_title = computed(() => {
  const display = schema.value.display[operator.value!]
  return display!.content(props.nodeProps.data.shippingRule)
})

const node_icon = computed(() => {
  const display = schema.value.display[operator.value!]
  return display!.icon
})

const from_icon = computed(() => {
  if (props.nodeProps.data.shippingRule.field_to_match.includes('from')) {
    return 'ChevronDoubleRight'
  }
  return null
})

const to_icon = computed(() => {
  if (
    props.nodeProps.data.shippingRule.field_to_match.includes('to') ||
    props.nodeProps.data.shippingRule.rule == 'ToCountryInEEA' ||
    props.nodeProps.data.shippingRule.rule == 'ToCompanyPresent'
  ) {
    return 'ChevronDoubleRight' as SuiteIconName
  }
  return null
})
</script>

<template>
  <NodeToolbar
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="nodeProps.data.toolbarVisible"
    :position="Position.Top"
  >
    <SuiteButton
      @click="$emit('edit', { nodeId: nodeProps.id })"
      style="margin-bottom: -25px"
      :style="nodeProps.data.canRemove ? 'margin-left: -40px;' : ''"
      class="h-7 w-7 rounded-full p-1.5"
      :theme="ThemeEnum.BLUE_OUTLINE"
      icon="Edit"
    />
  </NodeToolbar>

  <NodeToolbar
    v-if="nodeProps.data.canRemove"
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="nodeProps.data.toolbarVisible"
    :position="Position.Top"
  >
    <SuiteButton
      @click="$emit('remove', { nodeId: nodeProps.id })"
      style="margin-left: 40px; margin-bottom: -25px"
      class="h-7 w-7 rounded-full p-1.5"
      :theme="ThemeEnum.RED_OUTLINE"
      icon="X"
    />
  </NodeToolbar>

  <NodeToolbar
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="nodeProps.data.toolbarVisible"
    :position="Position.Right"
  >
    <SuiteButton
      @on-click="$emit('add-right', { nodeId: nodeProps.id })"
      style="margin-left: -25px"
      class="h-7 w-7 rounded-full p-1.5"
      :theme="ThemeEnum.BLUE_OUTLINE"
      icon="Plus"
    />
  </NodeToolbar>

  <NodeToolbar
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="resolutionState == 'resolved_final_match'"
    :position="Position.Right"
  >
    <ResolutionOutput :resolution-result="store.resolutionResult" />
  </NodeToolbar>

  <NodeToolbar
    style="display: flex; gap: 0.5rem; align-items: center"
    :is-visible="nodeProps.data.toolbarVisible"
    :position="Position.Bottom"
  >
    <SuiteButton
      @on-click="$emit('add-below', { nodeId: nodeProps.id })"
      style="margin-top: -25px"
      class="h-7 w-7 rounded-full p-1.5"
      :theme="ThemeEnum.BLUE_OUTLINE"
      icon="Plus"
    />
  </NodeToolbar>

  <div class="flex flex-col py-3 text-sm" :class="resolutionStateColor">
    <div class="suite-text-gray-800 flex justify-center">
      <SuiteIcon v-if="to_icon" :icon="to_icon" class="my-auto size-6" />
      <SuiteIcon :icon="node_icon" class="size-10" />
      <SuiteIcon v-if="from_icon" :icon="from_icon" class="my-auto size-6" />
    </div>
    <div class="w-32 truncate px-3 text-center">
      {{ node_title }}
    </div>
  </div>

  <Handle type="target" :position="Position.Left" />
  <Handle type="source" :position="Position.Right" />
</template>

export const getRefreshToken = {
  path: 'oauth/token',
  method: 'POST'
}
export type refreshTokenResponse = {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  scope: string
  created_at: number
}

import type { User } from '@/api/shipcloud/me'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useCredentialsStore = defineStore(
  'credentials',
  () => {
    const accessToken = ref('')
    const refreshToken = ref('')
    const user = ref<User | undefined>()
    const userEmail = computed(() => user.value?.email || '')
    const returnbirdAccountId = computed(() => user.value?.return_account_id || '')
    const suiteFeaturesEnabled = computed<boolean | undefined>(() =>
      user.value?.subscription.features.includes('suite')
    )
    const routingRulesEnabled = computed<boolean>(
      () => !!user.value?.subscription.features.includes('routing_rules')
    )

    const clear = () => {
      accessToken.value = ''
      refreshToken.value = ''
      user.value = undefined
    }

    return {
      accessToken,
      clear,
      refreshToken,
      returnbirdAccountId,
      routingRulesEnabled,
      suiteFeaturesEnabled,
      user,
      userEmail
    }
  },
  { persist: true }
)

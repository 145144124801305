import { useAuthenticationV1 } from '@/composables/useAuthenticationV1'
import { useAuthenticationV2 } from '@/composables/useAuthenticationV2'
import { gtm } from '@/helpers'
import { useAuthenticationStore } from '@/stores/authentication'
import type { NavigationFailure, RouteLocationNormalizedGeneric } from 'vue-router'

const authFlow = async (
  checkAuthentication: () => Promise<void>,
  checkAuthorization: () => Promise<boolean>
): Promise<boolean> => {
  const authenticationStore = useAuthenticationStore()
  checkAuthentication()
  if (authenticationStore.recheck()) {
    const authorized = await checkAuthorization()
    if (authorized) authenticationStore.checked()
    else return false
  }
  return true
}

export const beforeEach = async (to: RouteLocationNormalizedGeneric) => {
  if (to.name === 'authentication-error') return

  const authenticationStore = useAuthenticationStore()
  if (to.path === '/v2') authenticationStore.authenticationMode = 'v2'

  if (authenticationStore.authenticationMode === 'v2') {
    const { checkAuthentication, checkAuthorization } = useAuthenticationV2()
    const authorized = await authFlow(checkAuthentication, checkAuthorization)
    if (!authorized) return { name: 'authentication-error' }
    if (!to.name) return { name: 'suite-entry-v2' }
  } else if (authenticationStore.authenticationMode === 'v1') {
    const { checkAuthentication, checkAuthorization } = useAuthenticationV1()
    const authorized = await authFlow(checkAuthentication, checkAuthorization)
    if (!authorized) return { name: 'authentication-error' }
    if (!to.name) return { name: 'suite-entry' }
  }
}

export const afterEach = (
  to: RouteLocationNormalizedGeneric,
  _: RouteLocationNormalizedGeneric,
  failure: void | NavigationFailure | undefined
) => {
  if (!failure) gtm.trackPage(to.path)
}

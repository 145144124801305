<script setup lang="ts">
import { onMounted, ref } from 'vue'

export interface ErrorMessagePanelProps {
  scrollIntoView?: boolean
}
const props = withDefaults(defineProps<ErrorMessagePanelProps>(), {
  scrollIntoView: false
})
const elementRef = ref<HTMLElement | undefined>()
onMounted(() => props.scrollIntoView && elementRef.value?.scrollIntoView({ behavior: 'smooth' }))
</script>

<template>
  <div ref="elementRef" class="rounded-md bg-red-400 p-4 text-center text-white">
    <slot></slot>
  </div>
</template>

import type { User } from '@/api/shipcloud/me'
import { useIntercom } from '@homebaseai/vue3-intercom'

export function useIntercomWidget(user: User) {
  useIntercom().boot({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    created_at: 0,
    custom_launcher_selector: '',
    email: user.email,
    hide_default_launcher: false,
    name: [user.first_name, user.last_name].join(' '),
    user_id: user.id
  })
}

import type { ShippingRule, ShippingRuleTree } from '@/api/shipcloud/shipping_rules'
import { Position, type Edge, type ElementData, type Node } from '@vue-flow/core'
import { ref, type Ref } from 'vue'

type ShippingRuleNodeAdditions = {
  parentNode: string
}
export type ShippingRuleNode = Node<any, any, string> & ShippingRuleNodeAdditions
type ShippingRuleParent = Node<any, any, string>
type ShippingRuleNodeBase = ShippingRuleParent | ShippingRuleNode

export function useShippingRulesGraph(shippingRuleTree: ShippingRuleTree) {
  const nodes: Ref<ShippingRuleNode[]> = ref([])
  const edges: Ref<Edge[]> = ref([])

  const pushNode = (node: ShippingRuleNode | ShippingRuleParent) => {
    nodes.value.push(node as ShippingRuleNode)
  }
  const pushEdge = (edge: Edge) => {
    edges.value.push(edge)
  }

  const parents = () =>
    nodes.value.filter((node: ShippingRuleNode) => node.data?.level !== undefined)

  const addNodeBelow = (nodeId: string): string => {
    const currentNode = findNode(nodeId)
    const currentParent = findParentNode(currentNode.parentNode)
    const incomingEdge = findIncomingEdge(currentNode.id)
    const incomingNode = findNode(incomingEdge.source)
    const newNode = buildNode(currentParent)
    const newEdge = buildEdge(incomingNode, newNode)

    pushNode(newNode)
    pushEdge(newEdge)
    updateNodeOrder(newNode.id, currentNode.position.y + 1)
    deepRealign()
    return newNode.id
  }
  const addNodeRight = (nodeId: string): string => {
    const currentNode = findNode(nodeId)
    const currentParent = findParentNode(currentNode.parentNode) as ShippingRuleParent
    const newParentId = `${currentNode.id}-level${currentParent.data.level + 1}` // move to helper
    let newParent = parents().find((parent) => parent.id == newParentId) as
      | ShippingRuleParent
      | undefined
    let newNodeOrderValue = 0
    if (!newParent) {
      newParent = buildParent(currentNode, currentParent)
      pushNode(newParent)
    } else {
      newNodeOrderValue = filterChildNodes(newParent.id).length
    }
    const newNode = buildNode(newParent)
    newNode.data.order = newNodeOrderValue
    const newEdge = buildEdge(currentNode, newNode)
    pushNode(newNode)
    pushEdge(newEdge)
    deepReorderParents(currentParent)
    deepRealign()
    return newNode.id
  }

  const updateNodeOrder = (nodeId: string, y: number) => {
    const currentNode = findNode(nodeId)
    currentNode.position.y = y
    const parentNode = findParentNode(currentNode.parentNode)
    const children = filterChildNodes(parentNode.id)
    children.sort((childA, childB) => childA.position.y - childB.position.y)
    children.forEach((childNode, index) => {
      childNode.data.order = index
    })
    deepReorderParents(parentNode)
    deepRealign()
  }

  const canRemoveNode = (nodeId: string): boolean => {
    const node = findNode(nodeId)
    // only allow to delete leaf nodes, but prevent to delete the last remaining node
    return findOutgoingEdge(node.id) == undefined && edges.value.length > 1
  }
  const selectNode = (nodeId: string) => {
    const node = findNode(nodeId)
    node.data.canRemove = canRemoveNode(nodeId)
  }

  const removeNode = (nodeId: string) => {
    const node = findNode(nodeId)
    if (!canRemoveNode(node.id)) return

    const parent = findParentNode(node.parentNode)
    edges.value.splice(edges.value.indexOf(findIncomingEdge(nodeId)), 1)
    nodes.value.splice(nodes.value.indexOf(node), 1)
    if (!filterChildNodes(parent.id).length) {
      nodes.value.splice(nodes.value.indexOf(parent as ShippingRuleNode), 1)
    }
  }

  const editNode = (nodeId: string, data: ElementData) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...ruleData } = data // ruleData is data without the children property
    const node = findNode(nodeId)

    node.data.shippingRule = {
      ...node.data.shippingRule,
      ...ruleData
    }

    nodes.value.splice(findNodeIndex(nodeId), 1, node)
  }

  // sets the order of the next level's parents
  // needs to be called after dragging a node or adding/removing a parent
  const deepReorderParents = (leftParent: ShippingRuleParent) => {
    // we need an ordered list of all outgoing edges across the current level
    const outgoingEdges: Edge[] = []
    const sameLevelParents = filterSameLevelParents(leftParent.data.level)
    ordered(sameLevelParents).forEach((parent: ShippingRuleParent) => {
      const children = filterChildNodes(parent.id)
      ordered(children).forEach((childNode: ShippingRuleNode) => {
        const edge = findOutgoingEdge(childNode.id)
        if (edge) outgoingEdges.push(edge)
      })
    })
    // with these outgoing edges, we can find the right parent nodes
    const rightParents: ShippingRuleParent[] = []
    outgoingEdges.forEach((edge) => {
      const rightNode = findNode(edge.target) as ShippingRuleNode
      const rightParent = findParentNode(rightNode.parentNode)
      if (rightParent && !rightParents.includes(rightParent)) rightParents.push(rightParent)
    })
    // set the order attribute and traverse to the right
    rightParents.forEach((parent: ShippingRuleParent, index: number) => {
      parent.data.order = index
      deepReorderParents(parent)
    })
  }

  // render previously ordered parents and nodes
  const deepRealign = () => {
    const nodeHeight = 100
    const yOffset = 50
    // traverse each level to position and resize parents
    for (let level = 1; ; level++) {
      const sameLevelParents = filterSameLevelParents(level)
      if (sameLevelParents.length == 0) break

      let previousParentBottomY = 0
      ordered(sameLevelParents).forEach((parentNode) => {
        const parent = parentNode as ShippingRuleParent
        const childNodes = filterChildNodes(parent.id)
        setY(parent, previousParentBottomY)
        const height = setHeight(parent, childNodes.length * nodeHeight + yOffset)
        previousParentBottomY = previousParentBottomY + height

        ordered(childNodes).forEach((childNode, childIndex) => {
          const child = childNode as ShippingRuleNode
          setY(child, nodeHeight * childIndex + yOffset)
        })
      })
    }
  }

  const buildGraph = (currentNodeId: string, nextRule: ShippingRule) => {
    const nextNodeId = addNodeRight(currentNodeId)
    const nextNode = findNode(nextNodeId)
    nextNode.data = { ...nextNode.data, shippingRule: nextRule }
    nextRule.children.forEach((childRule) => {
      buildGraph(nextNodeId, childRule)
    })
  }
  // TODO: we'll need a better return if the tree is invalid
  const buildTree = (): ShippingRuleTree => {
    return {
      rules: buildRules('root'),
      active: true,
      persisted: false,
      version: shippingRuleTree.version + 1
    }
  }
  const buildRules = (currentNodeId: string): ShippingRule[] => {
    const rules: ShippingRule[] = []
    const rightNodes = edges.value
      .filter((edge) => edge.source == currentNodeId)
      .map((outgoingEdge) => findNode(outgoingEdge.target))
    ordered(rightNodes).forEach((rightNode) => {
      const currentRule = { ...rightNode.data.shippingRule, children: [] } as ShippingRule
      buildRules(rightNode.id).forEach((rule) => currentRule.children.push(rule))
      rules.push(currentRule)
    })
    return rules
  }

  // private
  const findParentNode = (id: string): ShippingRuleParent =>
    parents().find((parent) => parent.id == id) as ShippingRuleParent
  const findNode = (id: string) =>
    nodes.value.find((node: ShippingRuleNode) => node.id == id) as ShippingRuleNode
  const findNodeIndex = (id: string) =>
    nodes.value.findIndex((node: ShippingRuleNode) => node.id == id) as number
  const findIncomingEdge = (id: string): Edge =>
    edges.value.find((edge: Edge) => edge.target == id) as Edge
  const findOutgoingEdge = (id: string): Edge | undefined =>
    edges.value.find((edge: Edge) => edge.source == id)
  const filterSameLevelParents = (level: number): ShippingRuleParent[] =>
    parents().filter((parent: ShippingRuleParent) => parent.data.level == level)
  const filterChildNodes = (parentId: string): ShippingRuleNode[] =>
    nodes.value.filter((node: ShippingRuleNode) => node.parentNode == parentId)
  const ordered = (collection: any[]): any[] =>
    collection.sort((a, b) => a.data.order - b.data.order)
  const setHeight = (parent: ShippingRuleParent, height: number): number => {
    if (parent.style) {
      parent.style = { ...parent.style, height: toHeight(height) }
    }
    return height
  }
  const setY = (node: ShippingRuleNodeBase, y: number) => {
    node.position.y = y
  }

  const toHeight = (height: number): string => `${height}px`
  const buildParent = (
    node: ShippingRuleNode,
    currentParent: ShippingRuleParent
  ): ShippingRuleParent => {
    const nextLevel = currentParent.data.level + 1
    const id = `${node.id}-level${nextLevel}`
    return {
      id: id,
      label: id,
      style: {
        width: '200px',
        height: '200px',
        visibility: 'hidden'
      },
      class: 'border border-dashed suite-border-sky rounded-lg',
      position: {
        x: currentParent.position.x + 300,
        y: node.position.y - 100 // need to do this manually as well. Count all heights of the same level
      },
      type: 'shipping-rule-parent',
      data: {
        level: nextLevel,
        order: 0
      },
      selectable: false,
      draggable: false,
      connectable: false,
      deletable: false
    }
  }

  const buildNode = (parent: any): ShippingRuleNode => {
    const id = `node-${self.crypto.randomUUID()}`
    return {
      id: id,
      label: id,
      parentNode: parent.id,
      extent: 'parent',
      position: { x: 30, y: 0 },
      targetPosition: Position.Left,
      sourcePosition: Position.Right,
      expandParent: false,
      type: 'shipping-rule',
      deletable: false,
      data: {
        canRemove: false,
        shippingRule: dummyShippingRule()
      },
      class: 'border suite-border-sky rounded-lg border-opacity-60 bg-white suite-text-gray-600'
    }
  }

  const dummyShippingRule = (): ShippingRule => {
    return {
      output: [],
      rule: 'Equals',
      rule_name: '',
      field_to_match: ['from', 'city'],
      value_to_match: '',
      children: []
    }
  }

  const buildEdge = (source: ShippingRuleNode, target: ShippingRuleNode): Edge => {
    const id = `${source.id}->${target.id}`
    return {
      id: id,
      source: source.id,
      target: target.id,
      updatable: false,
      deletable: false,
      style: {
        stroke: '#99E1F3'
      }
    }
  }

  const rootParent: ShippingRuleParent = {
    id: 'level0',
    label: 'level0',
    style: {
      width: '200px',
      height: '100px'
    },
    position: { x: -250, y: 0 },
    type: 'shipping-rule-parent',
    data: {
      level: 0,
      order: 0
    },
    selectable: false,
    draggable: false,
    connectable: false,
    deletable: false
  }
  const rootNode: ShippingRuleNode = {
    id: 'root',
    label: 'root',
    type: 'input',
    parentNode: 'level0',
    position: { x: 30, y: 50 },
    sourcePosition: Position.Right,
    data: {
      order: 0
    },
    draggable: false,
    connectable: false,
    deletable: false,
    class: '!rounded-lg'
  }
  pushNode(rootParent)
  pushNode(rootNode)
  shippingRuleTree.rules.forEach((firstLevelRule) => {
    buildGraph('root', firstLevelRule)
  })

  return {
    nodes,
    edges,
    addNodeBelow,
    addNodeRight,
    updateNodeOrder,
    removeNode,
    selectNode,
    findNode,
    editNode,
    buildTree
  }
}

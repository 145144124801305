<script setup lang="ts">
import type { ShipmentQuote } from '@/api/shipcloud/shipment_quotes'
import { SuiteButton, ThemeEnum } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

const { n } = useI18n()
export interface ShipmentFormRatesProps {
  carrierLabel?: string
  carrierName?: string
  quote?: ShipmentQuote
  serviceLabel?: string
  strHeading?: string
  strBtnRefresh?: string
  strContent?: string
  strPricePrefix?: string
}

withDefaults(defineProps<ShipmentFormRatesProps>(), {
  strContent: 'Transit times may be estimated',
  strBtnRefresh: 'Refresh',
  strHeading: 'Rates',
  strPricePrefix: 'Cost'
})
defineEmits(['refresh'])
</script>

<template>
  <div class="pt-2">
    <div class="flex items-center">
      <h3 class="flex-1">{{ strHeading }}</h3>
      <SuiteButton
        :theme="ThemeEnum.BLUE_BORDERLESS"
        id="refresh-rates"
        icon="RotationRight"
        class="font-bold"
        @click="$emit('refresh')"
        >{{ strBtnRefresh }}</SuiteButton
      >
    </div>
    <div v-if="strContent" class="suite-text-gray-600 py-2 text-sm">{{ strContent }}</div>
    <div v-if="quote" class="quote-info rounded-md border-2 border-[#02b140] p-4 shadow">
      <div class="quote grid grid-cols-[3rem_auto_auto] items-center gap-x-4 text-sm">
        <span class="row-span-2">
          <img
            class="rounded-md bg-gray-100 p-1"
            :alt="''"
            :src="`https://suite-static-assets.s3.eu-central-1.amazonaws.com/carriers/${carrierName}.png`"
            :title="carrierLabel"
          />
        </span>
        <span>{{ carrierLabel }}</span>
        <span class="col-span-2 col-start-2">{{ serviceLabel }}</span>
        <span class="col-start-3 row-start-1 text-right">
          {{ strPricePrefix }}
          {{ n(quote.shipment_quote?.price, 'currency') }}
        </span>
      </div>
    </div>
  </div>
</template>

import { type ShippingRule, type ShippingRuleType } from '@/api/shipcloud/shipping_rules'

import type { SuiteIconName } from '@shipcloud/suite-components'
import {
  country,
  countryInEEA,
  isReturnShipment,
  longestSide,
  packageWeight,
  toCompanyPresent,
  valueWithOperator,
  zipCode
} from './shippingRuleOutputs'

type ShipmentLocationKey =
  | 'care_of'
  | 'city'
  | 'company'
  | 'country'
  | 'email'
  | 'first_name'
  | 'last_name'
  | 'phone'
  | 'street_no'
  | 'street'
  | 'zip_code'
type ShipmentPackageKey = 'height' | 'length' | 'type' | 'weight' | 'width'

type FieldToMatchFrom = ['from', ShipmentLocationKey]
type FieldToMatchTo = ['to', ShipmentLocationKey]
type FieldToMatchPackage = ['package', ShipmentPackageKey]
type FieldToMatch = FieldToMatchFrom | FieldToMatchTo | FieldToMatchPackage

export type ShippingRuleOperators = '<=' | '=' | '!=' | '>=' | 'in'
type ShippingRuleOperatorsWildcard = '<=' | '=' | '!=' | '>=' | 'in' | '*'

export type ShippingRuleInputType =
  | 'Text'
  | 'Number'
  | 'NumberMinMax'
  | 'Country'
  | 'CountryMultiple'

type ShippingRuleInputs = Partial<{
  [key in ShippingRuleOperatorsWildcard]: ShippingRuleInputType
}>
type ShippingRuleRules = Partial<{
  [key in ShippingRuleOperators]: ShippingRuleType
}>
type ShippingRuleDisplay = Partial<{
  [key in ShippingRuleOperators]: {
    icon: SuiteIconName
    content: (rule: ShippingRule) => string | string[]
  }
}>

interface SchemaBase {
  label: string
  rules: ShippingRuleRules
  display: ShippingRuleDisplay
}
interface SchemaRegular extends SchemaBase {
  fieldToMatch: FieldToMatch
  inputs: ShippingRuleInputs
}
interface SchemaBoolean extends SchemaBase {
  booleanRule: true
}
interface SchemaImplicitField extends SchemaBase {
  inputs: ShippingRuleInputs
}

interface SchemaImplementationBase {
  inputs: SchemaRegular['inputs']
  rules: SchemaRegular['rules']
  display: SchemaRegular['display']
}

const schemaBaseCountry: SchemaImplementationBase = {
  inputs: {
    in: 'CountryMultiple',
    '*': 'Country'
  },
  rules: {
    '=': 'ToCountryEquals', // Missing FromCountryEquals?
    '!=': 'NotEquals',
    in: 'IsInCollection'
  },
  display: {
    '=': { icon: 'RrCountry', content: (rule) => country(rule, '=') },
    '!=': { icon: 'RrCountry', content: (rule) => country(rule, '!=') },
    in: { icon: 'RrCountry', content: country }
  }
}

const schemaBaseZipcode: SchemaImplementationBase = {
  rules: {
    '=': 'IsInCollection',
    '!=': 'NotEquals',
    in: 'IsBetween'
  },
  inputs: {
    in: 'NumberMinMax',
    '*': 'Text'
  },
  display: {
    '=': { icon: 'RrZipcode', content: (rule) => zipCode(rule, '=') },
    '!=': { icon: 'RrZipcode', content: (rule) => zipCode(rule, '!=') },
    in: { icon: 'RrZipcode', content: zipCode }
  }
}

const schemaBaseString: SchemaImplementationBase = {
  rules: {
    '=': 'Equals',
    '!=': 'NotEquals'
  },
  inputs: { '*': 'Text' },
  display: {}
}

const schemaBaseNumber: SchemaImplementationBase = {
  rules: {
    '<=': 'LowerThanEquals',
    '=': 'Equals',
    '!=': 'NotEquals',
    '>=': 'GreaterThanEquals'
  },
  inputs: { '*': 'Number' },
  display: {}
}

export type ShippingRuleSchema = SchemaBoolean | SchemaRegular | SchemaImplicitField

export const shippingRuleSchemas: ShippingRuleSchema[] = [
  {
    ...schemaBaseString,
    label: 'strShippingRuleSchemaFromCity',
    fieldToMatch: ['from', 'city'],
    display: {
      '=': { icon: 'RrCity', content: (rule) => valueWithOperator(rule, '=') },
      '!=': { icon: 'RrCity', content: (rule) => valueWithOperator(rule, '!=') }
    }
  },
  {
    ...schemaBaseString,
    label: 'strShippingRuleSchemaFromCompany',
    fieldToMatch: ['from', 'company'],
    display: {
      '=': { icon: 'RrCompany', content: (rule) => valueWithOperator(rule, '=') },
      '!=': { icon: 'RrCompany', content: (rule) => valueWithOperator(rule, '!=') }
    }
  },
  {
    ...schemaBaseCountry,
    label: 'strShippingRuleSchemaFromCountry',
    fieldToMatch: ['from', 'country']
  },
  {
    ...schemaBaseZipcode,
    label: 'strShippingRuleSchemaFromZipCode',
    fieldToMatch: ['from', 'zip_code']
  },
  {
    ...schemaBaseString,
    label: 'strShippingRuleSchemaToCity',
    fieldToMatch: ['to', 'city'],
    display: {
      '=': { icon: 'RrCity', content: (rule) => valueWithOperator(rule, '=') },
      '!=': { icon: 'RrCity', content: (rule) => valueWithOperator(rule, '!=') }
    }
  },
  {
    ...schemaBaseString,
    label: 'strShippingRuleSchemaToCompany',
    fieldToMatch: ['to', 'company'],
    display: {
      '=': { icon: 'RrCompany', content: (rule) => valueWithOperator(rule, '=') },
      '!=': { icon: 'RrCompany', content: (rule) => valueWithOperator(rule, '!=') }
    }
  },
  {
    ...schemaBaseCountry,
    label: 'strShippingRuleSchemaToCountry',
    fieldToMatch: ['to', 'country']
  },
  {
    ...schemaBaseZipcode,
    label: 'strShippingRuleSchemaToZipCode',
    fieldToMatch: ['to', 'zip_code']
  },
  {
    ...schemaBaseNumber,
    label: 'strShippingRuleSchemaPackageWeight',
    fieldToMatch: ['package', 'weight'],
    display: {
      '=': { icon: 'RrWeight', content: (rule) => packageWeight(rule, '=') },
      '<=': { icon: 'RrWeight', content: (rule) => packageWeight(rule, '<=') },
      '>=': { icon: 'RrWeight', content: (rule) => packageWeight(rule, '>=') },
      '!=': { icon: 'RrWeight', content: (rule) => packageWeight(rule, '!=') }
    }
  },
  {
    label: 'strShippingRuleSchemaPackageLongestSide',
    rules: { '<=': 'LongestSideLowerThanEquals' },
    inputs: { '<=': 'Number' },
    display: {
      '<=': { icon: 'RrVolume', content: longestSide }
    }
  },
  {
    label: 'strShippingRuleSchemaReceiverCountryInEea',
    booleanRule: true,
    rules: { '=': 'ToCountryInEEA' },
    display: {
      '=': { icon: 'RrCountry', content: countryInEEA }
    }
  },
  {
    label: 'strShippingRuleSchemaReceiverCompanyNotEmpty',
    booleanRule: true,
    rules: { '=': 'ToCompanyPresent' },
    display: {
      '=': { icon: 'RrCompany', content: toCompanyPresent }
    }
  },
  {
    label: 'strShippingRuleSchemaReturnShipment',
    booleanRule: true,
    rules: { '=': 'IsReturnShipment' },
    display: {
      '=': { icon: 'RrReturn', content: isReturnShipment }
    }
  }
]

export const findSchemaIndexForRule = (
  shippingRule: ShippingRule | undefined,
  schemas: ShippingRuleSchema[]
) => {
  if (!shippingRule?.rule) return -1
  return schemas.findIndex((schema) => {
    // Check whether shippingrule.rule is within the schema.rules
    const ruleInSchema = Object.values(schema.rules).includes(shippingRule.rule)
    // Check if shippingrule.field_to_match matches schema.fieldToMatch
    // If current schema lacks a fieldToMatch (boolean rule), default to true
    const fieldToMatchMatch =
      'fieldToMatch' in schema
        ? JSON.stringify(schema.fieldToMatch) === JSON.stringify(shippingRule.field_to_match)
        : true

    return ruleInSchema && fieldToMatchMatch
  })
}

export const findOperatorForRule = (
  shippingRule: ShippingRule | undefined,
  schema: ShippingRuleSchema | undefined
) => {
  if (!shippingRule?.rule || !schema || !schema.rules) return undefined
  return Object.keys(schema.rules).find(
    (operator) => schema.rules[operator as ShippingRuleOperators] === shippingRule.rule
  ) as ShippingRuleOperators
}

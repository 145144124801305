import { z } from 'zod'
import { TrackingEvent } from './tracking_event'

export const Package = z.object({
  id: z.string(),
  length: z.number(),
  width: z.number(),
  height: z.number(),
  weight: z.number(),
  description: z.string().optional(),
  type: z.string(),
  tracking_events: z.array(TrackingEvent)
})
export type Package = z.infer<typeof Package>
export const NewPackage = Package.omit({ id: true, tracking_events: true })
export type NewPackage = z.infer<typeof NewPackage>

export type Carrier = {
  name: string
  display_name: string
  services: string[]
  additional_services: string[]
}

export const GetCarriers = {
  path: 'v1/carriers',
  method: 'GET'
}

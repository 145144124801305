<script setup lang="ts">
import type { DropdownMenuItemSelectedEvent, NavigationItem } from '@shipcloud/suite-components'
import {
  HeaderDropdown,
  HeaderNavigation,
  SuiteAppLayout,
  SuiteIcon
} from '@shipcloud/suite-components'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterView, useRouter } from 'vue-router'

import { localizeURL } from '@/helpers'
import { languages, setLocale } from '@/i18n'
import { useCredentialsStore } from '@/stores/credentials'
import { computed, type ComputedRef } from 'vue'
import { useIntercomWidget } from '../composables/useIntercomWidget'

const { locale, t, tm } = useI18n({ useScope: 'global' })

const router = useRouter()
const { hasRoute } = router
const credentialsStore = useCredentialsStore()
const app_host = localizeURL(locale.value)

const returnAuthUrl = import.meta.env.VITE_RETURN_AUTH_URL
const { returnbirdAccountId, routingRulesEnabled, user, userEmail } = storeToRefs(credentialsStore)
const headerBarProps = { logoHref: router.resolve({ name: 'suite-entry' }).href }
const footerBarProps = {
  languages,
  initialLanguage: locale.value,
  linkDisplayNames: tm('FooterBar.links')
}

const headerDropdownItems = [
  { id: 'edit_password', label: t('App.HeaderDropdown.edit_password') },
  { id: 'logout', label: t('App.HeaderDropdown.logout') }
]
const headerItemSelected = (event: DropdownMenuItemSelectedEvent) => {
  if (event?.item?.id === 'logout') {
    logout()
  }
  if (event?.item?.id === 'edit_password') {
    editPassword()
  }
}
const editPassword = () => {
  location.href = `${app_host}/users/edit`
}
const logout = () => {
  credentialsStore.clear()
  location.href = `${app_host}/sign_out`
}
const buildNavigationItems: ComputedRef<NavigationItem[]> = computed(() => {
  let shipments: NavigationItem = {
    id: 'shipments-dashboard',
    title: t('Navigation.shipments'),
    children: [
      {
        id: 'create-shipment',
        title: t('Navigation.createShipment')
      }
    ]
  }
  let children = [shipments]
  if (routingRulesEnabled.value) {
    children.push({
      id: 'routing-rules',
      title: t('Navigation.routingRules')
    })
  }
  children.push({
    id: localizeURL(locale.value),
    title: t('Navigation.settings')
  })
  let parents: NavigationItem[] = [
    {
      id: 'suite-entry',
      title: t('Navigation.shipping'),
      children: children
    }
  ]
  if (returnbirdAccountId.value) parents.push({ id: returnAuthUrl, title: t('Navigation.return') })

  return parents
})

if (user.value) useIntercomWidget(user.value)
</script>

<template>
  <SuiteAppLayout :headerBarProps :footerBarProps @language-changed="setLocale($event.newLanguage)">
    <template #header-center>
      <HeaderNavigation :items="buildNavigationItems">
        <template #nav-items="{ navItem, closeNav }">
          <router-link
            v-if="hasRoute(navItem.id)"
            :to="{ name: navItem.id }"
            @click="closeNav"
            active-class="suite-text-sky"
            class="hover:suite-text-sky"
          >
            {{ navItem.title }}
          </router-link>
          <a v-else :href="navItem.id" class="hover:suite-text-sky">
            {{ navItem.title }}
          </a>
        </template>
      </HeaderNavigation>
    </template>
    <template #header-right v-if="userEmail">
      <HeaderDropdown
        :items="headerDropdownItems"
        :title="userEmail"
        @on-select="headerItemSelected"
        :darkMode="false"
      >
        <template #menu-icon>
          <SuiteIcon icon="User" class="mr-2 size-4" />
        </template>
      </HeaderDropdown>
    </template>
    <template #default><RouterView /></template>
  </SuiteAppLayout>
</template>

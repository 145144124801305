import { Address } from '@/api/shipcloud/address'
import { z } from 'zod'

const CustomsDeclarationItem = z.object({
  id: z.string(),
  description: z.string(),
  hs_tariff_number: z.string().nullable(),
  gross_weight: z.number().nullable(),
  net_weight: z.number(),
  origin_country: z.string(),
  quantity: z.number(),
  value_amount: z.string(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable()
})
export type CustomsDeclarationItem = z.infer<typeof CustomsDeclarationItem>

const NewCustomsDeclarationItem = CustomsDeclarationItem.omit({
  id: true,
  created_at: true,
  updated_at: true
}).extend({ formId: z.string().optional() })
export type NewCustomsDeclarationItem = z.infer<typeof NewCustomsDeclarationItem>

export const CustomsDeclaration = z.object({
  id: z.string(),
  contents_type: z.string(),
  contents_explanation: z.string().nullable(),
  invoice_number: z.string().nullable(),
  exporter_reference: z.string().nullable(),
  importer_reference: z.string().nullable(),
  drop_off_location: z.string().nullable(),
  posting_date: z.string().nullable(),
  additional_fees: z.string().nullable(),
  total_value_amount: z.string(),
  currency: z.string(),
  document_type: z.string().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  carrier_declaration_document_url: z.string().nullable(),
  items: z.array(CustomsDeclarationItem),
  buyer_address: z.optional(Address)
})
export type CustomsDeclaration = z.infer<typeof CustomsDeclaration>
export const NewCustomsDeclaration = CustomsDeclaration.omit({
  id: true,
  created_at: true,
  updated_at: true,
  carrier_declaration_document_url: true
}).extend({ buyer_address: z.optional(Address), items: z.array(NewCustomsDeclarationItem) })
export type NewCustomsDeclaration = z.infer<typeof NewCustomsDeclaration>

export const customsDeclarationContentsTypes = [
  'commercial_goods',
  'commercial_sample',
  'documents',
  'gift',
  'returned_goods'
] as const

export const customsDeclarationDocumentTypes = [
  'cn_22',
  'cn_23',
  'commercial_invoice',
  'etd_commercial_invoice',
  'no_documents',
  'proforma_invoice'
] as const

import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import type { PaginationOptions, ShipcloudApiError } from '@/api/shipcloud'
import { ShipcloudAPI, isShipcloudApiError } from '@/api/shipcloud'
import type { NewShipment, Shipment, ShipmentFilter } from '@/api/shipcloud/shipment'

interface ShipmentsStore {
  [id: string]: Shipment
}

export const useShipmentsStore = defineStore('shipments', () => {
  const shipcloudApi = new ShipcloudAPI()

  const shipments: Ref<ShipmentsStore> = ref({})
  const shipmentsTotal = ref(0)
  const loading = ref(false)
  const saving = ref(false)
  const saveSuccess = ref(false)
  const inlineReturnId = ref<string>()
  const error: Ref<ShipcloudApiError | undefined> = ref()

  const shipmentById = computed(() => {
    return (id: string) => {
      return shipments.value[id]
    }
  })

  const fetchShipment = async (id: string) => {
    loading.value = true
    return shipcloudApi.getShipment(id).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        shipments.value[response.id] = response
        error.value = undefined
      }
      loading.value = false
    })
  }

  const fetchShipments = async (
    shipmentFilters: ShipmentFilter,
    paginationOptions: PaginationOptions
  ) => {
    loading.value = true
    return shipcloudApi.getShipments(shipmentFilters, paginationOptions).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        shipments.value = {}

        shipmentsTotal.value = response.total

        response.shipments.forEach((shipment) => {
          shipments.value[shipment.id] = shipment
        })
        error.value = undefined
      }
      loading.value = false
    })
  }

  const createShipment = async (shipment: NewShipment): Promise<string | undefined> => {
    saving.value = true
    return shipcloudApi.createShipment(shipment).then((response) => {
      saving.value = false
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        shipments.value[response.id] = response
        saveSuccess.value = true
        error.value = undefined
        return response.id
      }
    })
  }

  const getShipmentQuote = async (shipment: NewShipment) => {
    return shipcloudApi.getShipmentQuote(shipment).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        error.value = undefined
        return response
      }
    })
  }

  const deleteShipment = async (id: string) => {
    loading.value = true
    return shipcloudApi.deleteShipment(id).then((response) => {
      loading.value = false

      if (isShipcloudApiError(response)) {
        error.value = response
        return false
      } else {
        delete shipments.value[id]
        error.value = undefined
        return true
      }
    })
  }

  return {
    createShipment,
    fetchShipment,
    fetchShipments,
    getShipmentQuote,
    deleteShipment,
    error,
    inlineReturnId,
    loading,
    saveSuccess,
    saving,
    shipmentById,
    shipments,
    shipmentsTotal
  }
})

import type { ShippingRule, ShippingRuleType } from '@/api/shipcloud/shipping_rules'
import type { SuiteSelectOption } from '@shipcloud/suite-components'
import { computed, nextTick, ref, toRaw, watch, type Component } from 'vue'
import * as inputs from './RuleInputs'
import {
  findOperatorForRule,
  findSchemaIndexForRule,
  type ShippingRuleInputType,
  type ShippingRuleOperators,
  type ShippingRuleSchema
} from './shippingRuleSchemas'

interface UseRuleEditorOptions {
  rule?: ShippingRule
  schemas?: ShippingRuleSchema[]
}

const stateFromRule = (shippingRule: ShippingRule | undefined, schemas: ShippingRuleSchema[]) => {
  const schemaIndex = findSchemaIndexForRule(shippingRule, schemas)
  const operator = findOperatorForRule(shippingRule, schemas?.[schemaIndex])
  const value =
    schemaIndex !== -1 && operator !== undefined ? shippingRule?.value_to_match : undefined
  return { schemaIndex, operator, value }
}

export const useShippingRuleEditor = (opts: UseRuleEditorOptions = {}) => {
  // The shipping rule schemas
  const schemas = ref<ShippingRuleSchema[]>([])
  const setSchemas = (newSchemas: ShippingRuleSchema[]) => (schemas.value = newSchemas)
  if (opts.schemas) setSchemas(opts.schemas)

  // Boolean to indicate if a new rule is being processed.
  // Meant to disable watchers when multiple values are being set.
  const processingRule = ref(false)

  // Currently selected schema index. Initial -1 means no selection
  const activeSchemaIndex = ref(-1)

  // Rule selector options.
  const schemaOptions = computed<SuiteSelectOption[]>(() =>
    schemas.value.map((rule, index) => ({
      value: index,
      label: rule.label,
      disabled: false,
      selected: false
    }))
  )

  // Currently active schema, based on selected activeSchemaIndex
  const activeSchema = computed<ShippingRuleSchema | undefined>(
    () => schemas.value?.[activeSchemaIndex.value] || undefined
  )

  // Currently selected operator
  const activeOperator = ref<ShippingRuleOperators>()

  // Currently available operators, based on currently active schema
  const operators = computed<SuiteSelectOption<ShippingRuleOperators>[]>(() =>
    Object.keys(activeSchema.value?.rules || {}).map((operator) => ({
      value: operator as ShippingRuleOperators,
      label: operator
    }))
  )

  // Reset the activeOperator on activeSchemaIndex change
  watch(
    () => activeSchemaIndex.value,
    () => {
      // If rule is being processed, do nothing.
      if (processingRule.value) return

      // Changing activeSchemaIndex invalidates the activeOperator.
      // Set it to the first available operators option.
      activeOperator.value = operators.value?.[0]?.value
    }
  )

  // Currently active input type, based on selected schema and operator
  const activeInputType = computed<ShippingRuleInputType | 'Boolean' | undefined>(() => {
    if (!activeSchema.value) return undefined
    if ('booleanRule' in activeSchema.value && activeSchema.value.booleanRule) return 'Boolean'
    if (!activeOperator.value || !('inputs' in activeSchema.value)) return undefined
    return activeSchema.value?.inputs?.[activeOperator.value] || activeSchema.value?.inputs?.['*']
  })

  // Currently active input component, based on selected schema and operator
  const activeInputComponent = computed<{ component: Component; props?: Object } | undefined>(
    () => {
      if (!activeInputType.value) return undefined
      if (activeInputType.value === 'CountryMultiple')
        return { component: inputs.RuleInputCountry, props: { multiple: true } }
      const inputsKey = `RuleInput${activeInputType.value}` as keyof typeof inputs
      return inputs?.[inputsKey]
        ? { component: inputs[inputsKey], props: { modelValue: undefined } }
        : undefined
    }
  )

  // Rule outputs
  const ruleOutputs = ref<ShippingRule['output']>([])
  const ruleOutputsAdd = () => ruleOutputs.value.push({ carrier: '', service: '' })
  const ruleOutputsDelete = (index: number) =>
    ruleOutputs.value?.[index] && ruleOutputs.value.splice(index, 1)

  // Rule value
  const ruleValue = ref()

  // Upon input change, reset the current value
  watch(
    () => activeInputType.value,
    (newVal, oldVal) => {
      if (oldVal === undefined) return // Initial setting of input name.
      if (processingRule.value) return // If rule is being processed, do nothing.
      ruleValue.value = undefined // Input name changed. Reset value.
    }
  )

  // Process new shippingRule
  const processRule = async (shippingRule: ShippingRule | undefined) => {
    // Tell everything in this scope that a rule is being processed.
    processingRule.value = true

    // Process given rule and grab values to set.
    const { schemaIndex, operator, value } = stateFromRule(shippingRule, schemas.value)

    // Wait for processingRule update before setting refs.
    await nextTick()

    activeSchemaIndex.value = schemaIndex
    activeOperator.value = operator
    ruleOutputs.value = toRaw(shippingRule?.output || [])
    ruleValue.value = value

    // Wait for refs to be set before unlocking watchers.
    await nextTick()

    // Processing done. Release watchers.
    processingRule.value = false
  }

  // The shipping rule created from current selections. If invalid, returns undefined
  const updatePayload = computed(() => {
    if (!activeSchema.value) return undefined
    if (!activeOperator.value) return undefined
    if (!ruleOutputs.value.every((output) => !!output.carrier && !!output.service)) return undefined
    if (!('booleanRule' in activeSchema.value) && !ruleValue.value) return undefined

    const field_to_match: ShippingRule['field_to_match'] =
      'fieldToMatch' in activeSchema.value ? toRaw(activeSchema.value.fieldToMatch) : []
    const rule: ShippingRule['rule'] = activeSchema.value.rules[
      activeOperator.value
    ] as ShippingRuleType
    const value_to_match: ShippingRule['value_to_match'] = ruleValue.value
    const rule_name: ShippingRule['rule_name'] = [
      field_to_match.join('.'),
      activeOperator.value,
      value_to_match
    ].join(' ')
    const output: ShippingRule['output'] = toRaw(ruleOutputs.value)
    const children: ShippingRule['children'] = []

    const out: ShippingRule = {
      field_to_match,
      rule,
      value_to_match,
      rule_name,
      output,
      children
    }
    return out
  })

  // If a shipping rule was given, process it now
  if (opts.rule) processRule(opts.rule)

  return {
    activeInputComponent,
    activeOperator,
    activeSchemaIndex,
    ruleOutputsAdd,
    ruleOutputsDelete,
    operators,
    processRule,
    ruleOutputs,
    ruleValue,
    schemaOptions,
    setSchemas,
    updatePayload
  }
}

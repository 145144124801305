<script setup lang="ts">
import { SuiteIcon } from '@shipcloud/suite-components'
import { ref, watch } from 'vue'

const props = withDefaults(defineProps<{ autoHide?: boolean }>(), { autoHide: false })
const dismissed = ref(false)
const hidden = ref(false)
if (props.autoHide) {
  setTimeout(() => (dismissed.value = true), 2000)
}
watch(dismissed, () => {
  if (dismissed.value) setTimeout(() => (hidden.value = true), 1000)
})
</script>

<template>
  <div
    v-if="!hidden"
    :class="{ 'opacity-0': dismissed }"
    class="suite-bg-green-600 p-4 text-center text-white transition-opacity duration-1000"
  >
    <div class="suite-mx-auto suite-max-w-screen-2xl suite-px-8">
      <div
        v-if="!props.autoHide"
        class="float-right size-6 text-white hover:cursor-pointer hover:text-gray-300"
      >
        <SuiteIcon @click="dismissed = true" icon="X" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

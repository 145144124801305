import { parseTokenPayload } from '@/api/authentication/token'
import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import { useCasAuthentication } from '@shipcloud/suite-components'

export const useAuthenticationV2 = () => {
  const store = useAuthenticationV2Store()
  const { login, verifyLogin, logout, changePassword } = useCasAuthentication(
    window.location.origin,
    import.meta.env.VITE_CAS_HOST,
    import.meta.env.VITE_CAS_CLIENT_ID
  )

  const checkAuthentication = async () => {
    if (String(window.location.search).length) {
      const tokenPayload = await verifyLogin(window.location.search, store.challenge)
      if (!tokenPayload) return

      store.token = tokenPayload.token
      store.payload = parseTokenPayload(tokenPayload.payload)
    } else if (!store.valid()) {
      const loginParams = await login()
      store.challenge = loginParams.code
      window.location.href = `${loginParams.url}&locale=en`
    }
  }

  const checkAuthorization = async (): Promise<boolean> => {
    return store.valid()
  }

  const editAuthentication = async () => {
    store.clear()
    const redirectTo = changePassword()
    window.location.href = redirectTo.url
  }

  const removeAuthentication = async () => {
    store.clear()
    const redirectTo = logout()
    window.location.href = redirectTo.url
  }

  return { checkAuthentication, checkAuthorization, editAuthentication, removeAuthentication }
}

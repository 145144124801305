import { z } from 'zod'

export const TrackingStatus = z.enum([
  'awaits_pickup_by_receiver',
  'canceled',
  'delayed',
  'delivered',
  'destroyed',
  'exception',
  'ignored',
  'label_created',
  'not_delivered',
  'notification',
  'out_for_delivery',
  'picked_up',
  'transit'
])
export type TrackingStatus = z.infer<typeof TrackingStatus>

export const TrackingEvent = z.object({
  id: z.string(),
  status: TrackingStatus,
  timestamp: z.string(),
  location: z.string(),
  details: z.string()
})
export type TrackingEvent = z.infer<typeof TrackingEvent>

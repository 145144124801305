<script setup lang="ts">
import { useAuthenticationV2 } from '@/composables/useAuthenticationV2'
import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import {
  HeaderDropdown,
  SuiteAppLayout,
  SuiteIcon,
  type DropdownMenuItemSelectedEvent
} from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const store = useAuthenticationV2Store()
const { removeAuthentication, editAuthentication } = useAuthenticationV2()
const headerBarProps = { logoHref: router.resolve({ name: 'suite-entry-v2' }).href }
const userEmail = computed(() => store.payload?.sub)
const headerDropdownItems = [
  { id: 'edit_password', label: t('App.HeaderDropdown.edit_password') },
  { id: 'logout', label: t('App.HeaderDropdown.logout') }
]
const headerItemSelected = (event: DropdownMenuItemSelectedEvent) => {
  switch (event?.item?.id) {
    case 'logout':
      return removeAuthentication()
    case 'edit_password':
      return editAuthentication()
  }
}
</script>

<template>
  <SuiteAppLayout :headerBarProps>
    <template #header-right v-if="userEmail">
      <HeaderDropdown
        :items="headerDropdownItems"
        :title="userEmail"
        @on-select="headerItemSelected"
        :darkMode="false"
      >
        <template #menu-icon>
          <SuiteIcon icon="User" class="mr-2 size-4" />
        </template>
      </HeaderDropdown>
    </template>
    <template #default><RouterView /></template>
  </SuiteAppLayout>
</template>

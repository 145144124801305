import { getUserLocales } from 'get-user-locale'
import i18nIsocountries from 'i18n-iso-countries'
import countriesDe from 'i18n-iso-countries/langs/de.json'
import countriesEn from 'i18n-iso-countries/langs/en.json'
import countriesEs from 'i18n-iso-countries/langs/es.json'
import countriesIt from 'i18n-iso-countries/langs/it.json'
import countriesNl from 'i18n-iso-countries/langs/nl.json'
import { createI18n } from 'vue-i18n'

import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import it from '@/locales/it.json'
import nl from '@/locales/nl.json'

export const languages = {
  en: 'English',
  de: 'Deutsch',
  nl: 'Nederlands',
  es: 'Español',
  it: 'Italiano'
}

i18nIsocountries.registerLocale(countriesEn)
i18nIsocountries.registerLocale(countriesDe)
i18nIsocountries.registerLocale(countriesEs)
i18nIsocountries.registerLocale(countriesIt)
i18nIsocountries.registerLocale(countriesNl)

const messages = { de, en, nl, it, es }
const fallbackLocale = 'en'

const locale = (() => {
  const savedLocale = localStorage.getItem('locale')
  const userLocales = getUserLocales().map((locale) => locale.split('-')[0])
  const availableLocales = Object.keys(messages)
  const locale = [savedLocale !== null ? savedLocale : '', ...userLocales]
    .filter(Boolean)
    .filter((locale) => availableLocales.includes(locale))[0]
  localStorage.setItem('locale', locale)
  return locale
})()

const setLocale = (locale: string) => {
  localStorage.setItem('locale', locale)
  location.reload()
}

const countries = i18nIsocountries.getNames(locale)
const defaultNumberFormat: any = {
  currency: {
    style: 'currency',
    currency: 'EUR',
    notation: 'standard',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
}
const numberFormats = Object.fromEntries(
  Object.keys(languages).map((locale) => [locale, defaultNumberFormat])
)
const i18n = createI18n({
  messages,
  legacy: false,
  locale,
  fallbackLocale,
  missingWarn: false,
  numberFormats
})

export { countries, locale, setLocale }
export default i18n

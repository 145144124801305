<script setup lang="ts">
import type { ShipcloudApiError } from '@/api/shipcloud'
import { errorOn } from '@/api/shipcloud'
import type { Address } from '@/api/shipcloud/address'
import type { NewCustomsDeclaration, NewCustomsDeclarationItem } from '@/api/shipcloud/shipment'
import {
  customsDeclarationContentsTypes,
  customsDeclarationDocumentTypes
} from '@/api/shipcloud/shipment'
import {
  SimpleDataTable,
  SuiteButton,
  SuiteInput,
  SuiteInputLabel,
  SuiteSelect,
  ThemeEnum,
  type SimpleDataTableColumn,
  type SimpleDataTableItem
} from '@shipcloud/suite-components'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ShippingRuleInputCountry from '../ShippingRuleEditor/RuleInputs/ShippingRuleInputCountry.vue'
import ShipmentFormAddressForm from './ShipmentFormAddressForm.vue'

defineProps<{ error?: ShipcloudApiError }>()

const { t } = useI18n()
const customsDeclaration = defineModel<NewCustomsDeclaration>()
watch(
  () => customsDeclaration.value?.items?.length,
  () => customsDeclaration.value?.items.forEach((item) => (item.formId ??= crypto.randomUUID())),
  { immediate: true }
)
const defaultCustomsDeclaration = { items: [] } as unknown as NewCustomsDeclaration
const contentsTypeOptions = customsDeclarationContentsTypes.map((value) => {
  return { value, label: t(`CustomsDeclaration.contents_types.${value}`) }
})
const documentTypeOptions = customsDeclarationDocumentTypes.map((value) => {
  return { value, label: t(`CustomsDeclaration.document_types.${value}`) }
})
const addBuyerAddress = () => (customsDeclaration.value!.buyer_address = { country: '' } as Address)
const addCustomsDeclarationItem = () => {
  customsDeclaration.value!.items.push({} as NewCustomsDeclarationItem)
}

const removeCustomsDeclarationItem = (itemId: string) => {
  if (!customsDeclaration.value?.items) return

  const itemIndex = customsDeclaration.value.items.findIndex((item) => item.formId === itemId)

  if (itemIndex !== -1) {
    customsDeclaration.value.items.splice(itemIndex, 1)
  }
}

const customsDeclarationItemColumns: SimpleDataTableColumn[] = [
  { field: 'quantity', label: t('CustomsDeclarationItem.quantity') },
  { field: 'description', label: t('CustomsDeclarationItem.description') },
  { field: 'value_amount', label: t('CustomsDeclarationItem.value_amount') },
  { field: 'net_weight', label: t('CustomsDeclarationItem.net_weight') },
  { field: 'gross_weight', label: t('CustomsDeclarationItem.gross_weight') },
  { field: 'origin_country', label: t('CustomsDeclarationItem.origin_country') },
  { field: 'hs_tariff_number', label: t('CustomsDeclarationItem.hs_tariff_number') }
]
</script>

<template>
  <div class="suite-inline-flex suite-items-center suite-justify-start suite-gap-2 my-3">
    <div class="suite-h-4 suite-w-1 suite-bg-sky suite-bg-opacity-50"></div>
    <div class="suite-text-sm suite-font-bold suite-leading-snug suite-text-gray-600">
      {{ t('Office.ShipmentFormCustomsDeclaration.header') }}
    </div>
  </div>
  <div class="flex items-center">
    <template v-if="customsDeclaration">
      <div class="suite-text-gray-600 grow whitespace-break-spaces text-sm leading-relaxed">
        {{ t('Office.ShipmentFormCustomsDeclaration.removeHint') }}
      </div>
      <div class="justify-self-end">
        <SuiteButton
          id="customs-declaration-remove"
          :theme="ThemeEnum.BLUE_BORDERLESS"
          @click="customsDeclaration = undefined"
          icon="Minus"
        >
          {{ t('Office.ShipmentFormCustomsDeclaration.remove') }}
        </SuiteButton>
      </div>
    </template>
    <template v-else>
      <div class="suite-text-gray-600 grow whitespace-break-spaces text-sm leading-relaxed">
        {{ t('Office.ShipmentFormCustomsDeclaration.addHint') }}
      </div>
      <div class="justify-self-end">
        <SuiteButton
          id="customs-declaration-add"
          :theme="ThemeEnum.BLUE_BORDERLESS"
          @click="customsDeclaration = defaultCustomsDeclaration"
          icon="Plus"
        >
          {{ t('Office.ShipmentFormCustomsDeclaration.add') }}
        </SuiteButton>
      </div>
    </template>
  </div>

  <template v-if="customsDeclaration">
    <div class="grid grid-cols-[auto_1fr] gap-2 xl:grid-cols-[auto_1fr_auto_1fr]">
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <span
            :class="[
              'w-32',
              { 'text-form-error': errorOn(error, { property: 'Category of item' }) }
            ]"
          >
            {{ t('CustomsDeclaration.contents_type') }}
          </span>
        </template>
        <SuiteSelect
          v-model="customsDeclaration.contents_type"
          :options="contentsTypeOptions"
          :class="[
            'w-80 xl:max-w-52',
            {
              'border-form-error': errorOn(error, { property: 'Category of item' })
            }
          ]"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.document_type') }}</div>
        </template>
        <SuiteSelect
          v-model="customsDeclaration.document_type"
          :options="documentTypeOptions"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.contents_explanation') }}</div>
        </template>
        <SuiteInput
          id="contents-explanation"
          v-model="customsDeclaration.contents_explanation"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label
          ><div class="w-32">{{ t('CustomsDeclaration.posting_date') }}</div></template
        >
        <SuiteInput
          id="posting-date"
          type="date"
          v-model="customsDeclaration.posting_date"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.invoice_number') }}</div>
        </template>
        <SuiteInput
          id="invoice-number"
          v-model="customsDeclaration.invoice_number"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.exporter_reference') }}</div>
        </template>
        <SuiteInput
          id="exporter-reference"
          v-model="customsDeclaration.exporter_reference"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.importer_reference') }}</div>
        </template>
        <SuiteInput
          id="importer-reference"
          v-model="customsDeclaration.importer_reference"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.drop_off_location') }}</div>
        </template>
        <SuiteInput
          id="drop-off-location"
          v-model="customsDeclaration.drop_off_location"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.total_value_amount') }}</div>
        </template>
        <SuiteInput
          id="total-value-amount"
          type="number"
          v-model="customsDeclaration.total_value_amount"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.additional_fees') }}</div>
        </template>
        <SuiteInput
          id="additional-fees"
          type="number"
          v-model="customsDeclaration.additional_fees"
          class="w-80 xl:max-w-52"
        />
      </SuiteInputLabel>
      <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
        <template #label>
          <div class="w-32">{{ t('CustomsDeclaration.currency') }}</div>
        </template>
        <SuiteInput id="currency" v-model="customsDeclaration.currency" class="w-80 xl:max-w-52" />
      </SuiteInputLabel>
    </div>

    <div class="my-4">
      <ShipmentFormAddressForm
        v-if="customsDeclaration.buyer_address"
        api-model-name="Buyer address"
        v-model="customsDeclaration.buyer_address"
        :title="t('CustomsDeclaration.buyer_address')"
        remove-button
        :error
      />
      <div v-else class="flex items-center">
        <div class="suite-text-gray-600 grow whitespace-break-spaces text-sm leading-relaxed">
          {{ t('Office.ShipmentFormCustomsDeclaration.addBuyerAddressHint') }}
        </div>
        <div class="justify-self-end">
          <SuiteButton
            id="customs-declaration-add-buyer-address"
            :theme="ThemeEnum.BLUE_BORDERLESS"
            @click="addBuyerAddress"
            icon="Plus"
          >
            {{ t('Office.ShipmentFormCustomsDeclaration.add') }}
          </SuiteButton>
        </div>
      </div>
    </div>

    <SimpleDataTable
      class="mt-4"
      :columns="customsDeclarationItemColumns"
      :items="customsDeclaration.items as SimpleDataTableItem[]"
      :expandable="true"
    >
      <template #expand-toggle="{ isExpanded }">
        <SuiteButton v-if="!isExpanded" data-test="customs-declaration-item-edit" icon="Edit" />
        <SuiteButton v-if="isExpanded" icon="Check" />
      </template>
      <template #expanded-row="{ rowData }">
        <div class="mb-2 text-base font-normal">
          <div class="grid grid-cols-[auto_1fr] gap-2 xl:grid-cols-[auto_1fr_auto_1fr]">
            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.quantity') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                type="number"
                min="1"
                :id="rowData.formId + '-quantity'"
                v-model="rowData.quantity"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.description') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                :id="rowData.formId + '-description'"
                v-model="rowData.description"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.value_amount') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                type="number"
                min="0"
                :id="rowData.formId + '-value_amount'"
                v-model="rowData.value_amount"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.net_weight') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                type="number"
                min="0"
                :id="rowData.formId + '-net_weight'"
                v-model="rowData.net_weight"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.gross_weight') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                type="number"
                min="0"
                :id="rowData.formId + '-gross_weight'"
                v-model="rowData.gross_weight"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.origin_country') }}
                </div>
              </template>
              <ShippingRuleInputCountry
                class="w-80 xl:max-w-52"
                :id="rowData.formId + '-origin_country'"
                v-model="rowData.origin_country"
              />
            </SuiteInputLabel>

            <SuiteInputLabel class="col-span-2 grid grid-cols-subgrid">
              <template #label>
                <div class="w-32">
                  {{ t('CustomsDeclarationItem.hs_tariff_number') }}
                </div>
              </template>
              <SuiteInput
                class="w-80 xl:max-w-52"
                :id="rowData.formId + '-hs_tariff_number'"
                v-model="rowData.hs_tariff_number"
              />
            </SuiteInputLabel>
          </div>
          <SuiteButton
            class="-ml-2 mt-3 bg-inherit"
            data-test="customs-declaration-item-remove"
            @click="removeCustomsDeclarationItem(rowData.formId)"
            :theme="ThemeEnum.BLUE_BORDERLESS"
            icon="Minus"
          >
            {{ t('Office.ShipmentFormCustomsDeclaration.removeItem') }}
          </SuiteButton>
        </div>
      </template>
    </SimpleDataTable>

    <SuiteButton
      @click="addCustomsDeclarationItem"
      data-test="customs-declaration-item-add"
      class="float-left mt-5 px-5"
      :theme="ThemeEnum.BLUE_BORDERLESS"
      icon="Plus"
    >
      {{ t('Office.ShipmentFormCustomsDeclaration.addItem') }}
    </SuiteButton>
  </template>
</template>

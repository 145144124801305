<script setup lang="ts">
import ErrorMessagePanel from '@/components/ErrorMessagePanel.vue'

const shipcloudUrl = import.meta.env.VITE_SEND_CORE_APP_HOST
</script>

<template>
  <div class="mx-auto max-w-screen-md">
    <div class="justify-content mx-4 my-10 flex items-center md:my-20 lg:my-32">
      <ErrorMessagePanel>
        <i18n-t keypath="App.Error.accountError402" tag="p">
          <template #shipcloud_url>
            <a :href="shipcloudUrl" target="_blank">
              {{ shipcloudUrl }}
            </a>
          </template>
        </i18n-t>
      </ErrorMessagePanel>
    </div>
  </div>
</template>

import { parseTokenPayload, type TokenPayload } from '@/api/authentication/token'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAuthenticationV2Store = defineStore(
  'authenticationV2',
  () => {
    const token = ref<string>('')
    const challenge = ref<string>('')
    const payload = ref<TokenPayload>()

    const valid = (): boolean => token.value.length > 0 && !!parseTokenPayload(payload.value)

    const clear = () => {
      token.value = ''
      challenge.value = ''
      payload.value = undefined
    }

    return { token, challenge, payload, clear, valid }
  },
  { persist: true }
)

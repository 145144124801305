<script lang="ts" setup>
import type { ShipcloudApiError } from '@/api/shipcloud'
import { errorOn } from '@/api/shipcloud'
import { AdditionalService, InlineReturn, NewShipment } from '@/api/shipcloud/shipment'
import {
  ShipmentFormAdditionalServices,
  ShipmentFormInlineReturn,
  ShipmentFormRates,
  type ShipmentFormRatesProps
} from '@/components/ShipmentForm'
import {
  SuiteButton,
  SuiteLoader,
  SuiteSelect,
  type SuiteSelectOption
} from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

defineProps<{
  carrierFilterOptions: SuiteSelectOption[]
  serviceFilterOptions?: SuiteSelectOption[]
  additionalServices?: AdditionalService[]
  additionalServiceErrors?: string[]
  shipmentQuoteInfo?: ShipmentFormRatesProps
  shipmentQuoteLoading: boolean
  defaultReturnsAddressPresent?: boolean
  renderInlineReturn?: boolean
  error: ShipcloudApiError | undefined
  saving: boolean
}>()
const shipment = defineModel<NewShipment>('shipment')
const inlineReturn = defineModel<InlineReturn>('inlineReturn')
const { t } = useI18n()
const emit = defineEmits<{
  (e: 'save'): void
  (e: 'shipmentQuoteFetch'): void
}>()
</script>

<template>
  <template v-if="shipment && carrierFilterOptions.length">
    <div class="suite-border-sky-10 space-y-4 rounded-lg border bg-white p-5 px-5">
      <div class="text-xl font-bold leading-tight">
        {{ t('Office.ShipmentForm.carrierSelection') }}
      </div>
      <div class="suite-text-gray-600 text-sm">
        {{ t('Office.ShipmentForm.carrierSelectionHint') }}
      </div>
      <div class="flex gap-3">
        <SuiteSelect
          id="select-carrier"
          :placeholder="t('Office.ShipmentForm.carrierSelectPlaceholder')"
          :options="carrierFilterOptions"
          v-model="shipment.carrier"
          class="max-w-48"
          :class="{ 'suite-border-red-300': errorOn(error, { property: 'carrier' }) }"
        />
        <SuiteSelect
          v-if="serviceFilterOptions"
          id="select-service"
          :placeholder="t('Office.ShipmentForm.serviceSelectPlaceholder')"
          :options="serviceFilterOptions"
          v-model="shipment.service"
          class="max-w-48"
          :class="{ 'suite-border-red-300': errorOn(error, { property: 'carrier' }) }"
        />
        <div v-else class="suite-text-gray-600 flex items-center">
          {{ t('Shipment.services.returns') }}
        </div>
      </div>
      <ShipmentFormInlineReturn v-if="renderInlineReturn" v-model="inlineReturn" />
      <div class="mt-5 flex gap-3">
        <SuiteLoader :loading="saving">
          <SuiteButton
            id="submit-button"
            class="inline-block min-w-40 px-5"
            @click="emit('save')"
            :disabled="saving"
          >
            {{ t('Office.ShipmentForm.save') }}
          </SuiteButton>
        </SuiteLoader>
      </div>
      <SuiteLoader :loading="shipmentQuoteLoading">
        <ShipmentFormRates
          class="border-t-2 border-dashed"
          v-bind="shipmentQuoteInfo"
          :str-btn-refresh="t('Office.ShipmentForm.RatesButtonRefresh')"
          str-content=""
          :str-heading="t('Office.ShipmentForm.RatesHeading')"
          :str-price-prefix="t('Office.ShipmentForm.RatesPriceprefix')"
          @refresh="() => emit('shipmentQuoteFetch')"
        />
      </SuiteLoader>
      <ShipmentFormAdditionalServices
        v-if="additionalServices"
        :additional-service-errors="additionalServiceErrors"
        :additional-services="additionalServices"
        v-model="shipment.additional_services"
      />
    </div>
  </template>
</template>

import { z } from 'zod'
import { Address } from './address'
import { AdditionalService, NewPackage, type NewShipment } from './shipment'
export interface ShipmentQuote {
  shipment_quote: {
    price: number
  }
}

const ShipmentQuotePayload = z.object({
  carrier: z.string(),
  service: z.string(),
  from: Address,
  to: Address.optional(),
  package: NewPackage,
  additional_services: z.array(AdditionalService).optional()
})
type ShipmentQuotePayload = z.infer<typeof ShipmentQuotePayload>

const editableShipmentToQuotePayload = (shipment: NewShipment): ShipmentQuotePayload => {
  const payload = {
    carrier: shipment.carrier,
    service: shipment.service,
    from: shipment.from,
    to: shipment.to,
    package: shipment.package,
    additional_services: shipment.additional_services
  }
  return payload
}

export const CreateShipmentQuote = (shipment: NewShipment) => ({
  path: `v1/shipment_quotes`,
  method: 'POST',
  data: editableShipmentToQuotePayload(shipment)
})

<script setup lang="ts">
import {
  LinkCard,
  type LinkCardNavigateEvent,
  type SuiteIconName
} from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
  title: string
  subtitle: string
  icon: SuiteIconName
  href?: string // use href for external links
  name?: string // use name for internal links
}>()

const router = useRouter()

const navigate = (navigationEvent: LinkCardNavigateEvent) => {
  if (props.href) {
    location.href = navigationEvent.href
  } else {
    router.push(navigationEvent.href)
  }
}

const linkCardHref = computed(() =>
  props.name ? router.resolve({ name: props.name }).fullPath : props.href || ''
)
</script>

<template>
  <LinkCard
    @click.prevent
    @navigate="navigate"
    :icon="props.icon"
    :title="props.title"
    :subtitle="props.subtitle"
    :href="linkCardHref"
  />
</template>

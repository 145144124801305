import { defineStore } from 'pinia'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref } from 'vue'

import type { PaginationOptions, ShipcloudApiError } from '@/api/shipcloud'
import { ShipcloudAPI, isShipcloudApiError } from '@/api/shipcloud'
import {
  defaultResolutionData,
  defaultShippingRuleTree,
  type CarrierServiceCombination,
  type ShippingRuleTree,
  type ShippingRuleTreeFilterOptions,
  type ShippingRuleTreeResolutionRequest
} from '@/api/shipcloud/shipping_rules'

interface ShippingRuleTreesStore {
  [id: string]: ShippingRuleTree
}

export const useShippingRuleTreesStore = defineStore('shippingRuleTrees', () => {
  const shipcloudApi = new ShipcloudAPI()

  const shippingRuleTrees: Ref<ShippingRuleTreesStore> = ref({})
  const resolutionResult: Ref<CarrierServiceCombination[]> = ref([])
  const resolutionData = ref(defaultResolutionData)
  const loading = ref(false)
  const error: Ref<ShipcloudApiError | undefined> = ref()
  const currentVersion = ref(0)

  const fetchShippingRuleTrees = async (
    filterOptions: ShippingRuleTreeFilterOptions,
    paginationOptions: PaginationOptions
  ) => {
    loading.value = true
    filterOptions = { ...filterOptions, active: true }
    return shipcloudApi.getShippingRuleTrees(filterOptions, paginationOptions).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        shippingRuleTrees.value = {}
        response.shipping_rule_trees.forEach((shippingRuleTree) => {
          shippingRuleTree.persisted = true
          shippingRuleTrees.value[shippingRuleTree.version] = shippingRuleTree
          currentVersion.value = shippingRuleTree.version
        })
      }
      loading.value = false
    })
  }

  const createShippingRuleTree = async (shippingRuleTree: ShippingRuleTree) => {
    loading.value = true
    return shipcloudApi.createShippingRuleTree(shippingRuleTree).then((response) => {
      if (isShipcloudApiError(response)) {
        loading.value = false
        error.value = response
      } else {
        shipcloudApi.activateShippingRuleTree(response).then((activateResponse) => {
          if (isShipcloudApiError(activateResponse)) {
            error.value = activateResponse
          } else {
            const tree = activateResponse
            tree.persisted = true
            shippingRuleTrees.value[tree.version] = tree
            currentVersion.value = tree.version
          }
          loading.value = false
        })
      }
    })
  }

  const createShippingRuleTreeResolution = async (request: ShippingRuleTreeResolutionRequest) => {
    loading.value = true
    return shipcloudApi.createShippingRuleTreeResolution(request).then((response) => {
      if (isShipcloudApiError(response)) {
        error.value = response
      } else {
        const tree = shippingRuleTrees.value[response.rule_tree_version]
        if (tree) {
          resolutionResult.value = response.carrier_service_combinations
          tree.rules = response.resolution_trace
        }
      }
      loading.value = false
    })
  }

  const latestShippingRuleTree: ComputedRef<ShippingRuleTree> = computed(
    () => shippingRuleTrees.value[currentVersion.value] || defaultShippingRuleTree
  )

  const validate = (resolutionDataInput: string): boolean => {
    try {
      const request: ShippingRuleTreeResolutionRequest = {
        version: latestShippingRuleTree.value.version,
        shipment_data: JSON.parse(resolutionDataInput)
      }
      createShippingRuleTreeResolution(request)
    } catch (e: any) {
      console.log(e)
      return false
    }
    return true
  }

  return {
    fetchShippingRuleTrees,
    createShippingRuleTree,
    createShippingRuleTreeResolution,
    latestShippingRuleTree,
    loading,
    error,
    shippingRuleTrees,
    resolutionData,
    resolutionResult,
    validate
  }
})

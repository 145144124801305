declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

export const trackPage = (pageType: string) => {
  window.dataLayer ??= []
  window.dataLayer.push({
    event: 'page',
    pageType
  })
}

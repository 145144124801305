import type { ShippingRule } from '@/api/shipcloud/shipping_rules'
import type { ShippingRuleOperators } from './shippingRuleSchemas'

const displayOperator = new Map([
  ['=', '='],
  ['<=', '≤'],
  ['>=', '≥'],
  ['!=', '≠']
])

export const valueWithOperator = (rule: ShippingRule, operator: ShippingRuleOperators) => {
  return `${displayOperator.get(operator)} ${rule.value_to_match}`
}

export const countryInEEA = (rule: ShippingRule) => {
  return rule.rule == 'ToCountryInEEA' ? 'in EEA' : ''
}

export const country = (rule: ShippingRule, operator?: ShippingRuleOperators) => {
  if (Array.isArray(rule.value_to_match)) {
    const numberOfCountriesToList = 3
    if (rule.value_to_match.length > numberOfCountriesToList) {
      return `${rule.value_to_match.slice(0, numberOfCountriesToList).join(', ')}, +${rule.value_to_match.length - numberOfCountriesToList}`
    } else {
      return rule.value_to_match.join(', ')
    }
  } else {
    return [displayOperator.get(operator!), rule.value_to_match].join(' ')
  }
}

export const zipCode = (rule: ShippingRule, operator?: ShippingRuleOperators) => {
  if (Array.isArray(rule.value_to_match)) {
    return rule.value_to_match.join(' - ')
  } else {
    return [displayOperator.get(operator!), rule.value_to_match].join(' ')
  }
}

export const packageWeight = (rule: ShippingRule, operator: ShippingRuleOperators) => {
  return `${displayOperator.get(operator)} ${rule.value_to_match} kg`
}

export const longestSide = (rule: ShippingRule) => `≤ ${rule.value_to_match} cm`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toCompanyPresent = (_rule: ShippingRule) => {
  return 'B2B'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isReturnShipment = (_rule: ShippingRule) => {
  return 'Return'
}

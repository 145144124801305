export type Me = {
  id: string
  email: string
  first_name: string
  last_name: string
  customer_no: string
  environment: string
  return_account_id: string
  subscription: {
    plan_name: string
    plan_display_name: string
    chargeable: boolean
    features: string[]
  }
}
export type User = Me

export const GetMe = {
  path: 'v1/me',
  method: 'GET'
}

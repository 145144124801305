import type { PaginationOptions } from '@/api/shipcloud'
import { toQueryParams } from '@/helpers'
import type { Address } from './address'

export type ShippingRuleTree = {
  version: number
  active: boolean
  rules: ShippingRule[]
  persisted: boolean
}
export type ShippingRuleTreeFilterOptions = {}
export type GetShippingRuleTreesResponse = {
  shipping_rule_trees: ShippingRuleTree[]
}

export const GetShippingRuleTrees = (
  filterOptions: ShippingRuleTreeFilterOptions,
  pagination: PaginationOptions
) => ({
  path: `v1/shipping_rule_trees?${toQueryParams(filterOptions, pagination).toString()}`,
  method: 'GET'
})

export const CreateShippingRuleTree = (shippingRuleTree: ShippingRuleTree) => ({
  path: `v1/shipping_rule_trees`,
  method: 'POST',
  data: { rules: shippingRuleTree.rules }
})

export const ActivateShippingRuleTree = (shippingRuleTree: ShippingRuleTree) => ({
  path: `v1/shipping_rule_trees/${shippingRuleTree.version}/activate`,
  method: 'POST'
})

export const CreateShippingRuleTreeResolution = (data: ShippingRuleTreeResolutionRequest) => ({
  path: `v1/shipping_rule_tree_resolutions`,
  method: 'POST',
  data: { ...data, include_trace: true }
})

export type ShippingRuleType =
  | 'Equals'
  | 'GreaterThanEquals'
  | 'IsBetween'
  | 'IsInCollection'
  | 'IsReturnShipment'
  | 'LongestSideLowerThanEquals'
  | 'LowerThanEquals'
  | 'NotEquals'
  | 'RuleResolutionDecorator'
  | 'RuleTreeDeserializer'
  | 'RuleTreePrinter'
  | 'RuleTreeResolver'
  | 'ToCompanyPresent'
  | 'ToCountryEquals'
  | 'ToCountryInEEA'
  | 'WeightLowerThanEquals'

export type ShippingRuleResolutionState =
  | 'unresolved'
  | 'resolved_no_match'
  | 'resolved_match'
  | 'resolved_final_match'

export type ShippingRuleResolution = {
  carrier: string
  service: string
}

export type ShippingRule = {
  id?: string
  output: ShippingRuleResolution[]
  rule: ShippingRuleType
  rule_name: string
  field_to_match: string[]
  resolution_state?: ShippingRuleResolutionState
  value_to_match: string | string[]
  children: ShippingRule[]
}

export type ShippingRuleTreeResolutionRequest = {
  version: number
  shipment_data: ShippingRuleTreeResolutionShipmentData
}
export type ShippingRuleTreeResolutionShipmentData = {
  from: Address
  to: Address
  package: {
    height: number
    length: number
    width: number
    weight: number
    type: string
  }
}
export type CarrierServiceCombination = {
  carrier: string
  service: string
}
export type ShippingRuleTreeResolutionResponse = {
  rule_tree_version: number
  carrier_service_combinations: CarrierServiceCombination[]
  resolution_trace: ShippingRule[]
}

export const defaultShippingRuleTree: ShippingRuleTree = {
  version: 1,
  active: false,
  persisted: false,
  rules: [
    {
      output: [],
      rule: 'ToCountryInEEA',
      rule_name: '',
      field_to_match: [],
      value_to_match: '',
      children: []
    }
  ]
}

export const defaultResolutionData = {
  from: {
    city: 'Hamburg',
    company: 'shipcloud',
    country: 'DE',
    zip_code: '20457'
  },
  to: {
    city: 'Bonn',
    company: 'shipcloud',
    country: 'DE',
    zip_code: '53113'
  },
  package: {
    height: 30,
    length: 50,
    weight: 1.3,
    width: 40
  }
}

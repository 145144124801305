<script setup lang="ts">
import type { AdditionalService } from '@/api/shipcloud/shipment'
import { SuiteButton, SuiteInput, SuiteInputLabel, ThemeEnum } from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    additionalServices: AdditionalService[]
    additionalServiceErrors?: string[]
    modelValue?: AdditionalService[]
  }>(),
  {
    additionalServices: () => [],
    additionalServiceErrors: () => [],
    modelValue: () => []
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', state: AdditionalService[]): unknown
}>()

const state = ref<AdditionalService[]>(props.modelValue)

watch(
  () => state.value,
  () => emit('update:modelValue', state.value),
  { deep: true, immediate: true }
)

watch(
  () => props.modelValue,
  () => (state.value = props.modelValue),
  { deep: true, immediate: true }
)

const included = computed(() => state.value.map((service) => service.name))

const add = (name: string) => state.value.push({ name, properties: {} })

const indexOf = (name: string) => state.value.findIndex((service) => service.name === name)

const remove = (name: string) => {
  const index = indexOf(name)
  if (index > -1) state.value.splice(index, 1)
}
</script>

<template>
  <div v-if="additionalServices.length">
    <h3>
      {{ t('Shipment.additional_services') }}
    </h3>
    <div v-for="additionalService in additionalServices" :key="additionalService.name">
      <div class="mt-2 grid max-w-lg grid-cols-2 items-center border-t py-2">
        <span
          :class="[
            'text-sm',
            'font-semibold',
            {
              'text-form-error': additionalServiceErrors?.includes(additionalService.name)
            }
          ]"
        >
          {{ t(`AdditionalService.names.${additionalService.name}`) }}
        </span>
        <div class="justify-self-end">
          <SuiteButton
            v-if="included.includes(additionalService.name)"
            :id="`additional-service-remove-${additionalService.name}`"
            :theme="ThemeEnum.BLUE_BORDERLESS"
            @click="remove(additionalService.name)"
            icon="Check"
          >
            {{ t('Office.ShipmentForm.added') }}
          </SuiteButton>
          <SuiteButton
            v-else
            :id="`additional-service-add-${additionalService.name}`"
            :theme="ThemeEnum.BLUE_BORDERLESS"
            @click="add(additionalService.name)"
            icon="Plus"
          >
            {{ t('Office.ShipmentForm.add') }}
          </SuiteButton>
        </div>
        <div
          v-if="
            included.includes(additionalService.name) && additionalService.properties instanceof Map
          "
          class="col-span-2"
        >
          <div v-for="[property] in additionalService.properties" :key="property">
            <SuiteInputLabel
              class="m:grid-cols-2 mt-3 grid grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2"
              :class="{
                'text-form-error': additionalServiceErrors?.includes(additionalService.name)
              }"
            >
              <template #label>{{ t(`AdditionalService.properties.${property}`) }}</template>
              <SuiteInput
                class="justify-self-end"
                :class="{
                  'border-form-error': additionalServiceErrors?.includes(additionalService.name)
                }"
                v-model="state[indexOf(additionalService.name)].properties[property]"
              />
            </SuiteInputLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { countries } from '@/i18n'
import { ComboBox, type ComboBoxOption } from '@shipcloud/suite-components'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    multiple?: boolean
    placeholder?: string
  }>(),
  {
    multiple: false,
    placeholder: 'Select a country'
  }
)
const value = defineModel<string | string[] | undefined>()

/*
  TODO In order to move this component to the component library, 
  the i18n link should be severed and these values should be given in another way. 
  Either through props or provide/inject.
*/
const countryItems = Object.entries(countries).map(
  ([key, value]): ComboBoxOption => ({ key, value })
)
const placeholder = computed(() =>
  props.multiple ? `${value.value?.length || 0} selected` : props.placeholder
)
</script>

<template>
  <ComboBox
    :multiple="multiple"
    :items="countryItems"
    :placeholder="placeholder"
    v-model:value="value"
  ></ComboBox>
</template>

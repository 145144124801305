import { defineStore } from 'pinia'
import { ref } from 'vue'

import { isShipcloudApiError, ShipcloudAPI } from '@/api/shipcloud'
import { type Address } from '@/api/shipcloud/address'

interface AddressesStore {
  [id: string]: Address
}

export const useAddressesStore = defineStore('addresses', () => {
  const shipcloudApi = new ShipcloudAPI()

  const addresses = ref<AddressesStore>({})
  const defaultShippingAddress = ref<Address | undefined>()
  const defaultReturnsAddress = ref<Address | undefined>()
  const loading = ref(false)
  const loadingDefaultReturnsAddress = ref(false)

  const fetchDefaultShippingAddress = () => {
    loading.value = true
    return shipcloudApi.getDefaultShippingAddress().then((response) => {
      if (isShipcloudApiError(response)) {
        console.error(response.errors)
      } else {
        defaultShippingAddress.value = response
        addresses.value[response.id!] = response
      }
      loading.value = false
    })
  }

  const fetchDefaultReturnsAddress = () => {
    loadingDefaultReturnsAddress.value = true
    return shipcloudApi.getDefaultReturnsAddress().then((response) => {
      if (isShipcloudApiError(response)) {
        console.error(response.errors)
      } else {
        defaultReturnsAddress.value = response
        addresses.value[response.id!] = response
      }
      loadingDefaultReturnsAddress.value = false
    })
  }

  return {
    addresses,
    defaultShippingAddress,
    defaultReturnsAddress,
    fetchDefaultShippingAddress,
    fetchDefaultReturnsAddress,
    loading,
    loadingDefaultReturnsAddress
  }
})

import { createRouter, createWebHistory, useRoute } from 'vue-router'
import { afterEach, beforeEach } from './hooks.ts'
import { routes } from './routes.ts'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})
router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
export { router, useRoute }

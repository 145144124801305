import { z } from 'zod'

export const Surcharge = z.object({
  amount: z.string(),
  category: z.string(),
  currency: z.string()
})
export type Surcharge = z.infer<typeof Surcharge>

export const Surcharges = z.object({
  pre_calculated: z.array(Surcharge).optional(),
  post_calculated: z.array(Surcharge).optional()
})
export type Surcharges = z.infer<typeof Surcharges>
